import React from "react";
import { Card } from "reactstrap";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { ResponsiveContainer } from "recharts";

class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
    };
    this.handleMapClick = this.handleMapClick.bind(this);
  }
  handleMapClick(ref, map, ev) {
    const location = ev.latLng;
    this.setState((prevState) => ({
      locations: [...prevState.locations, location],
    }));
    map.panTo(location);
  }

  render() {
    const { isTransferOpen, selectData, isIncidentOpen, change } = this.props;
    return (
      <div className="d-flex flex-fill">
        <Map
          google={this.props.google}
          className={"map border border-light border-3 rounded shadow"}
          zoom={this.props.zoom}
          containerStyle={{ width: "33.33vw", height: "60%" }}
          initialCenter={this.props.change}
          center={this.props.change}
        >
          <Marker position={this.props.change} title={this.props.city} />
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyDtnx3pwtsdjwMYdA8WKao5On80x946POM",
  libraries: [],
})(MapContainer);
