const initialState = {
  awlClient: null,
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'INIT_AWL_CLIENT': {
      // const { data } = action.payload;
      if (action.payload) {
        return {
          ...state,
          awlClient: action.payload
        };
      } else {
        return {
          ...state
        };
      }

    }
    case "DESTROY_AWL_CLIENT": {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}

