import React, { Component } from "react";
import { NotificationManager } from "react-notifications";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  userLogin,
  checkExistUserName,
  addUserOnAws,
} from "../../store/actions";
import logo from "../../images/logo-White.png";
import garda from "../../images/circle-cropped.png";
import _ from "lodash";

class Pageslogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      disable: true,
      enableUser: false,
      access_token: "",
      enablePassword: false,
      caspioData: {},
      confirmpassword: null,
      newpassword: null,
      passDisable: true,
      subDisable: true,
      error: null,
    };
  }

  handleOnChange(e) {
    if (e.target.value !== "") {
      this.setState({ [e.target.name]: e.target.value, subDisable: false });
    } else {
      this.setState({ [e.target.name]: e.target.value, subDisable: true });
    }
  }

  handleOnPasswordCreate(e) {
    if (e.target.value !== "") {
      this.setState({ [e.target.name]: e.target.value, passDisable: false });
    } else {
      this.setState({ [e.target.name]: e.target.value, passDisable: true });
    }
  }

  handleOnLoginSubmit(event) {
    event.preventDefault();
    let data = {
      username: _.toLower(this.state.username),
      password: this.state.password,
      history: this.props.history,
    };
    this.props.userLogin(data);
  }

  handleOnUserNameSubmit(event, values) {
    event.preventDefault();
    const { username } = this.state;
    this.props
      .checkExistUserName(username)
      .then((res) => {
        if (res && res.caspioData && res.enablePassword) {
          this.setState({
            enablePassword: true,
            enableUser: false,
            caspioData: res.caspioData,
          });
        } else if (res && res.enableUser) {
          this.setState({
            enableUser: true,
            username: username,
          });
        } else {
          NotificationManager.success("Username does not exist!");
        }
      })
      .catch((err) => {
        console.log(err, "===========-=-=-");
      });
  }

  handleOnChangePassword(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleOnChangeConfirmPassword(e) {
    if (this.state.newpassword === e.target.value) {
      this.setState({
        [e.target.name]: e.target.value,
        error: null,
        passDisable: false,
      });
    } else {
      this.setState({
        error: "Password not matched!",
        passDisable: true,
      });
    }
  }

  // if user not exist we add user data into mongodb
  handleOnPasswordCheck(e) {
    const { caspioData, newpassword } = this.state;
    let data = caspioData;
    let password = newpassword;
    this.props
      .addUserOnAws(data, password)
      .then((res) => {
        if (res && res.isSucess) {
          let payload = {
            username: _.toLower(res.username),
            password: newpassword,
            history: this.props.history,
          };
          this.props.userLogin(payload);
        } else {
          NotificationManager.success("Username does not exist!");
        }
      })
      .catch((err) => {
        console.log(err, "===========-=-=-=");
      });
  }

  FormInput({
    label = "",
    name = "",
    value = "",
    placeholder = "",
    type = "text",
    onChange = () => {},
  }) {
    return (
      <div className="mb-3">
        <label className="fw-bold">{label}</label>
        <input
          name={name}
          value={value}
          placeholder={placeholder}
          className="form-control"
          type={type}
          onChange={onChange}
          required
        />
      </div>
    );
  }

  render() {
    return (
      <div className="d-flex flex-fill flex-column centered p-5">
        <div className="card h-50">
          <div className="bg-blue text-white px-5 py-3">
            <img src={garda} height="45" alt="logo" />
            <img
              src={logo}
              height="57"
              alt="logo"
              className="ms-2"
              // style={{ width: "82%" }}
            />
          </div>
          <div className="card-body">
            {!this.state.enableUser ? (
              <form
                className="d-flex flex-fill h-100 flex-column justify-content-around"
                onSubmit={(e) => {
                  this.handleOnUserNameSubmit(e);
                }}
              >
                <this.FormInput
                  label="Username"
                  name="username"
                  value={this.state.username}
                  placeholder="Enter Username"
                  className="form-control"
                  type="text"
                  required
                  onChange={(e) => {
                    this.handleOnChange(e);
                  }}
                />

                <div className="form-group m-t-20">
                  <div className="text-right w-100">
                    <button
                      style={{ width: "100%" }}
                      disabled={this.state.subDisable ? true : false}
                      className="btn btn-primary w-md waves-effect waves-light"
                      type="submit"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              ""
            )}
            {this.state.enableUser ? (
              <form
                className="d-flex flex-fill h-100 flex-column justify-content-around"
                onSubmit={(e) => {
                  !this.state.enablePassword
                    ? this.handleOnLoginSubmit(e)
                    : this.handleOnPasswordCheck(e);
                }}
              >
                {!this.state.enablePassword && (
                  <this.FormInput
                    name="password"
                    value={this.state.password}
                    label="Password"
                    placeholder="Password"
                    type="password"
                    required
                    onChange={(e) => {
                      this.handleOnPasswordCreate(e);
                    }}
                  />
                )}
                {this.state.enablePassword && (
                  <this.FormInput
                    name="newpassword"
                    value={this.state.newpassword}
                    label="Create Password"
                    placeholder="Enter New Password"
                    type="password"
                    required
                    onChange={(e) => {
                      this.handleOnChange(e);
                    }}
                  />
                )}
                {this.state.enablePassword && (
                  <this.FormInput
                    name="confirmPassword"
                    value={this.state.confirmPassword}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    type="password"
                    required
                    onChange={(e) => {
                      this.handleOnChangeConfirmPassword(e);
                    }}
                  />
                )}
                <p style={{ color: "red" }}>{this.state.error}</p>
                <div className="form-group m-t-20">
                  <div className="w-100 text-right">
                    <button
                      style={{ width: "100%" }}
                      disabled={this.state.passDisable ? true : false}
                      color="primary"
                      className="btn bg-blue text-white waves-effect waves-light"
                      type="submit"
                    >
                      Log In
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="mt-4 text-center">
          <p>© {new Date().getFullYear()} GardaWorld</p>
        </div>
      </div>
    );
  }
}

const mapStatetoProps = (state) => {
  const { user, loginError, loading } = state.User;
  return { user, loginError, loading };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userLogin: (data) => dispatch(userLogin(data)),
    checkExistUserName: (username) => checkExistUserName(username),
    addUserOnAws: (caspio, password) => addUserOnAws(caspio, password),
  };
};

export default withRouter(
  connect(mapStatetoProps, mapDispatchToProps)(Pageslogin)
);
