import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import {
  addNewLog,
  apiGetLogs,
  apiGetSitesByJobNumber,
  getContactList,
  getLogs,
  initIncidentReport,
} from "../../../store/actions";
import Map from "../map";
import moment from "moment";

export const DetailsIcon = () => {
  const XY = 20;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={XY}
      height={XY}
      fill="currentColor"
      className="bi bi-list"
      viewBox="0 0 16 16"
    >
      <path
        fill-rule="evenodd"
        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
      />
    </svg>
  );
};

export const FormInput = ({
  value = "",
  setValue = () => {},
  type = "text",
  label = "NOT SET",
  disabled = false,
}) => (
  <div className="mb-4">
    <label className="lead fw-bold">{label}</label>
    <input
      type={type}
      className="form-control"
      value={value}
      onChange={(x) => setValue(x.target.value)}
      disabled={disabled}
    />
  </div>
);

export function DispatchTab({
  selectedJob,
  setSelectedJob,
  dispatcher,
  initIncidentReport,
}) {
  const height = `70vh`;
  const [logs, setLogs] = useState([]);
  const [selectedLog, setSelectedLog] = useState(
    JSON.parse(localStorage.getItem("@SELECTEDLOG")) || {}
  );
  const [dataTable, setDataTable] = useState([]);
  const [clients, setClients] = useState([]);
  const [sites, setSites] = useState([]);
  const [clientList, setClientList] = useState([]);
  const [activityTypes] = useState([
    { name: "Officer Check-In", id: "" },
    { name: "Incident - Job Site", id: "" },
    { name: "Incident - Workplace Injury to Officer", id: "" },
    { name: "Incident - Workplace Injury to Other(s)", id: "" },
    { name: "Incident - Workplace Injury to Other(s)", id: "" },
    { name: "IT - GW Network User Access Failure    ", id: "" },
    { name: "IT - GW User Access Requested    ", id: "" },
    { name: "IT - GW Staff Phone Technical Support Issue", id: "" },
    { name: "IT - GW Telephone System Failure", id: "" },
    { name: "Officer - Start Shift", id: "" },
    { name: "Officer - End Shift", id: "" },
    { name: "Officer - Requesting Manager Contact", id: "" },
    { name: "Sales - Client Complaint", id: "" },
    { name: "Sales - Client Request for Manager Contact", id: "" },
    { name: "Sales - Client Request for Service", id: "" },
    { name: "Sales - Prospect Request Follow-up", id: "" },
    { name: "Sales - Prospect Request for Call", id: "" },
    { name: "Staffing - Corrective Action Implemented", id: "" },
    { name: "Staffing - Employee Benefits Complaint", id: "" },
    { name: "Staffing - Employee Call Off", id: "" },
    { name: "Staffing - Employee No Show", id: "" },
    { name: "Staffing - Employee Pay Complaint", id: "" },
    { name: "Staffing - Leave or Sick Time", id: "" },
    { name: "Staffing - Payroll Issue referred to Branch HR", id: "" },
    { name: "Staffing - Payroll Issue referred to Corporate HR", id: "" },
    { name: "Staffing - Post Filled", id: "" },
    { name: "Staffing - Post Not Filled", id: "" },
    { name: "Staffing - Schedule Change Request", id: "" },
    { name: "Staffing - Schedule Question", id: "" },
    { name: "Staffing - Employee Separation - Voluntary", id: "" },
    { name: "Staffing - Employee Separation - Involuntary", id: "" },
    { name: "Team Time - Site Call-in Failure - Dispatcher Corrected", id: "" },
    {
      name: "Team Time - Site Call-in Failure - Dispatcher Unable to Correct",
      id: "",
    },
    { name: "Team Time - Manager Follow-up Required", id: "" },
    { name: "Uniform - Replacement or Issue", id: "" },
    { name: "Vehicle - Accident Reported", id: "" },
    { name: "Vehicle - Dead Battery", id: "" },
    { name: "Vehicle - Flat Tire", id: "" },
    { name: "Vehicle - Key Missing", id: "" },
    { name: "Vehicle - Maintenance Other", id: "" },
    { name: "Vehicle - Stolen", id: "" },
    { name: "Vehicle - Vandalism", id: "" },
  ]);
  const [view, setView] = useState(
    localStorage.getItem("@DISPATCHVIEW") || "table"
  );

  const [logsColumns] = useState([
    {
      name: "Time",
      selector: (row) => new Date(row?.created).toLocaleTimeString(),
      sortable: true,
    },
    {
      name: "Date",
      selector: (row) => new Date(row?.created).toLocaleDateString(),
      sortable: true,
    },
    {
      name: "Job Name",
      selector: (row) => row?.job?.name,
      sortable: true,
    },
    {
      name: "Job Number",
      selector: (row) => row?.job?.number,
      sortable: true,
    },
    {
      name: "Activity Type",
      selector: (row) => row.activity,
      sortable: true,
    },
    {
      name: "Caller Name",
      selector: (row) => row?.caller?.name,
      sortable: true,
    },
    {
      name: "Action",
      selector: (row) => row?.action,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
  ]);
  const [clientColumns] = useState([
    {
      name: "Company Name",
      selector: (row) => row?.companyName,
      sortable: true,
    },
    {
      name: "Job Name",
      selector: (row) => row?.jobName,
      sortable: true,
    },
    {
      name: "Job Number",
      selector: (row) => row?.jobNumber,
      sortable: true,
    },
    { name: "City", selector: (row) => row?.city, sortable: true },
    { name: "Action", selector: (row) => row?.action },
  ]);

  //   SEARCH STATE VALUES
  const [searchJobName, setSearchJobName] = useState("");
  const [searchJobNumber, setSearchJobNumber] = useState("");
  const [searchActivityType, setSearchActivityType] = useState("");
  const [searchCallerName, setSearchCallerName] = useState("");
  const [statusToggle, setStatusToggle] = useState("");

  //   FORM STATE VALUES
  const [callerName, setCallerName] = useState("");
  const [callerPhone, setCallerPhone] = useState("");
  const [activityType, setActivityType] = useState("");
  const [notes, setNotes] = useState(selectedLog?.notes || "");
  const [initReport, setInitReport] = useState(false);
  // const [emailSite, setEmailSite] = useState(false);
  const [status, setStatus] = useState("Closed");
  const [dateStart, setDateStart] = useState(
    moment().subtract(1, "days").format().split("T")[0]
  );
  const [dateEnd, setDateEnd] = useState(moment().format().split("T")[0]);

  // CLIENT LIST DATA PULL
  const getClientList = () => JSON.parse(localStorage.getItem("clientList"));

  // PROCESS CLIENT LIST FOR TABLE
  const processClientList = (list = []) => {
    const res = list?.map((item, key) => ({
      companyName: item.companyDetail.companyName,
      jobName: item.JobDescription,
      jobNumber: item.JobNumber,
      city: item.Address.City,
      action: (
        <button
          key={key}
          className="btn btn-sm btn-success"
          data-bs-target="#activityPopUp"
          data-bs-toggle="modal"
          onClick={() => onSelectSite(item)}
        >
          Choose Job
        </button>
      ),
    }));

    return res;
  };

  // PROCESS LOGS FOR TABLE
  const processLogs = (list) => {
    return list.map((x) => ({
      ...x,
      activity: <span className="text-wrap">{x?.activity}</span>,
      action: (
        <div className="d-flex centered flex-fill">
          <button
            className="btn btn-sm btn-danger"
            onClick={() => {
              localStorage.setItem("@SELECTEDLOG", JSON.stringify(x));
              localStorage.setItem("@DISPATCHVIEW", "details");
              setNotes(x?.notes);
              setSelectedLog(x);
              setView("details");
            }}
          >
            <DetailsIcon />
          </button>
        </div>
      ),
    }));
  };

  // ON CLEAR FORM
  const onClearForm = () => {
    setCallerName("");
    setCallerPhone("");
    setActivityType("");
    setNotes("");
    setInitReport(false);
    localStorage.removeItem("@SELECTEDLOG");
  };

  // FILTER CONTACTS
  const filterContacts = (e, type) => {
    if (e.target.value !== "") {
      if (type === "company") {
        setClientList(
          clients.filter((x) =>
            x?.companyDetail?.companyName
              ?.toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        );
      } else if (type === "job") {
        setClientList(
          clients.filter((x) =>
            x.JobDescription.toLowerCase().includes(
              e.target.value.toLowerCase()
            )
          )
        );
      } else if (type === "city") {
        setClientList(
          clients.filter((x) =>
            x.Address.City.toLowerCase().includes(e.target.value.toLowerCase())
          )
        );
      } else if (type === "jobNumber") {
        setClientList(clients.filter((x) => x.JobNumber === e.target.value));
      }
    } else {
      setClientList(getClientList());
    }
  };

  // ON ADD NEW LOG
  const onAddNewLog = async () => {
    const matchingSite = sites?.find((x) => x.jobNumber === selectedJob?.JobNumber);  // PRO
    // const matchingSite = sites?.find((x) => x.jobNumber === "Testing"); // TEST
    const newLog = {
      job: {
        name: selectedJob?.JobDescription,
        number: selectedJob?.JobNumber,
      },
      caller: {
        name: callerName,
        phone: callerPhone,
      },
      dispatcher: dispatcher?.id,
      activity: activityType,
      notes,
      status,
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    };
    const newReport = {
      customerId: matchingSite?.customerId,
      clientId: matchingSite?.clientId,
      siteId: matchingSite?.id,
      userId: "",
      checkpointId: "",
      activityTypeId: "",
      incidentTypeId: "",
      localSynchId: dispatcher?.id,
      values: {
        type: "incidentForm",
        lat: matchingSite?.location?.lat?.toString(),
        lng: matchingSite?.location?.lng?.toString(),
        dateofIncident: "",
        timeofIncident: "",
        incidentType: "",
        secondOfficer: "",
        additionalLocationInfo: "",
        authoritiesNotified: false,
        clientNotified: false,
        complainant: false,
        witnesses: false,
        safetyHazardsPresent: false,
        detailedDescriptionOfIncident: "",
        departmentNotified: "",
        timeCalled: "",
        timeofArrival: "",
        timeDeparted: "",
        responderName: "",
        policeReport: "",
        name: "",
        complainantsName: "",
        complainantsContactInfo: "",
        witnessName: "",
        witnessAddress: "",
        witnessContactInformation: "",
        report: "",
        propertyDescription: "",
        badgeNumber: "",
        todayDate: new Date().toISOString(),
        date: new Date().toISOString(),
      },
      status: "started",
      created: new Date().toISOString(),
      modified: new Date().toISOString(),
    };
    if (notes && activityType) {
      if (initReport) await initIncidentReport({ newReport });
      await addNewLog({ newLog });
      await onClearForm();
    }
  };

  // ON GET DISPATCER LOGS
  const onGetDispatcherLogs = async () => {
    const req = {
      dispatcher: dispatcher?.id,
      start: dateStart,
      end: dateEnd,
    };
    setLogs(await apiGetLogs(req));
  };

  // ON SELECT SITE
  const onSelectSite = async (site) => {
    setSelectedJob(site);
    setSites(await apiGetSitesByJobNumber({ jobNumber: site?.JobNumber })); // PRO
    // setSites(await apiGetSitesByJobNumber({ jobNumber: "Testing" })); // TEST
  };

  // AUTO-FETCH DATA
  useEffect(() => {
    if (!clientList?.length) {
      setClients(getClientList());
      setClientList(getClientList());
    }
    if (!logs?.length) onGetDispatcherLogs();
  }, []);

  // AUTO-REFRESH DATA TABLE
  useEffect(() => {
    let res = logs;
    if (searchJobName)
      res = res.filter((x) =>
        x?.job?.name?.toLowerCase().includes(searchJobName.toLowerCase())
      );
    if (searchJobNumber)
      res = res.filter((x) => x?.job?.number === searchJobNumber);
    if (searchActivityType)
      res = res.filter((x) =>
        x?.activity?.toLowerCase().includes(searchActivityType.toLowerCase())
      );
    if (searchCallerName)
      res = res.filter((x) =>
        x?.caller?.name?.toLowerCase().includes(searchCallerName.toLowerCase())
      );

    setDataTable(processLogs(res));
  }, [logs, searchJobName, searchJobNumber, activityType, callerName]);

  // AUTO-FETCH FOR SEARCH
  useEffect(() => {
    onGetDispatcherLogs();
  }, [dateStart, dateEnd]);

  return (
    <div className="d-flex flex-fill flex-column border bg-secondary">
      {/* TABLE VIEW */}
      {view === "table" && (
        <>
          {/* TOOL BAR A */}

          {/* TOOL BAR B */}
          <div className="d-flex justify-content-between align-items-center m-2">
            <div />

            <div className="d-flex">
              {/* DATE RANGE */}
              <div className="input-group">
                {/* TO */}
                <span className="input-group-text bg-blue text-light">
                  From
                </span>
                <input
                  type="date"
                  className="form-control"
                  value={dateStart}
                  onChange={(x) => setDateStart(x.target.value)}
                />
                {/* FROM */}
                <span className="input-group-text bg-blue text-light">To</span>
                <input
                  type="date"
                  className="form-control"
                  value={dateEnd}
                  onChange={(x) => setDateEnd(x.target.value)}
                />
              </div>
            </div>

            <button
              className="btn btn-sm btn-danger border-light"
              data-bs-toggle="modal"
              data-bs-target="#searchPopUp"
            >
              Create Activity
            </button>
          </div>
          <div className="d-flex w-100 m-0">
            <div className="d-flex flex-fill justify-content-between mx-2 mb-1">
              {/* SEARCH: JOB NAME */}
              <input
                className="form-control me-3"
                placeholder="Job Name"
                value={searchJobName}
                onChange={(x) => setSearchJobName(x.target.value)}
              />

              {/* SEARCH: JOB NUMBER */}
              <input
                className="form-control me-3"
                placeholder="Job Number"
                value={searchJobNumber}
                onChange={(x) => setSearchJobNumber(x.target.value)}
              />

              {/* SEARCH: ACTIVITY TYPE */}
              <input
                className="form-control me-3"
                placeholder="Activity Type"
                value={searchActivityType}
                onChange={(x) => setSearchActivityType(x.target.value)}
              />

              {/* SEARCH: CALLER NAME */}
              <input
                className="form-control"
                placeholder="Caller Name"
                value={searchCallerName}
                onChange={(x) => setSearchCallerName(x.target.value)}
              />
            </div>
          </div>
          {/* LOGS TABLE */}
          <div className="d-flex flex-column overflow-auto" style={{ height }}>
            <DataTable
              key={1}
              columns={logsColumns}
              data={dataTable}
              pagination
              paginationComponentOptions={{
                selectAllRowsItem: true,
                selectAllRowsItemText: "All",
              }}
            />
          </div>
        </>
      )}

      {/* DETAILS VIEW */}
      {view === "details" && (
        <div className="d-flex flex-column text-light p-3" style={{ height }}>
          {/* TITLE BAR */}
          <div className="d-flex align-items-center justify-content-between mb-3">
            {/* BACK BTN */}
            <button
              className="btn btn-sm btn-dark"
              onClick={() => {
                onClearForm();
                localStorage.setItem("@DISPATCHVIEW", "table");
                setView("table");
              }}
            >
              Back
            </button>

            {/* UPDATE BTN */}
            {/* <div>
              <button
                className="btn btn-sm btn-light"
                data-bs-toggle="modal"
                data-bs-target="#confirmUpdatePopUp"
              >
                Add Note
              </button>
            </div> */}
          </div>

          {/* LOG DETAILS */}
          <div className="d-flex flex-column">
            <div className="d-flex justify-content-center">
              {/* KEY INFO */}
              <div className="col me-3">
                <FormInput
                  label="Activity Type"
                  value={selectedLog?.activity}
                  disabled={true}
                />
                <FormInput
                  label="Created On"
                  value={new Date(selectedLog?.created).toLocaleString()}
                  disabled={true}
                />
              </div>

              {/* JOB INFO */}
              <div className="col me-3">
                <FormInput
                  label="Job Name"
                  value={selectedLog?.job?.name}
                  disabled={true}
                />
                <FormInput
                  label="Job Number"
                  value={selectedLog?.job?.number}
                  disabled={true}
                />
              </div>

              {/* CALLER INFO */}
              <div className="col">
                <FormInput
                  label="Caller Name"
                  value={selectedLog?.caller?.name}
                  disabled={true}
                />
                <FormInput
                  label="Caller Phone Number"
                  value={selectedLog?.caller?.phone}
                  disabled={true}
                />
              </div>
            </div>

            {/* DISPATCHER NOTES */}
            <label className="lead fw-bold">Dispatcher Notes</label>
            <textarea className="form-control" value={notes} disabled />
          </div>
        </div>
      )}

      {/* SEARCH  MODAL */}
      <div id="searchPopUp" className="modal fade" data-bs-backdrop="static">
        <div className="modal-dialog" style={{ maxWidth: `75vw` }}>
          <div className="modal-content">
            {/* HEADER */}
            <div
              className="modal-header text-light"
              style={{
                backgroundColor: "#de2c23",
              }}
            >
              <b style={{ fontSize: "22px" }}>Job Search</b>
              <button
                className="btn btn-close bg-light"
                onClick={() => onClearForm()}
                data-bs-dismiss="modal"
              />
            </div>

            {/* BODY */}
            <div className="modal-body">
              {/* FORM INPUT BAR */}
              <div
                className="d-flex mb-3"
                style={{ backgroundColor: "#E1E2E0" }}
              >
                <Input
                  style={{ marginLeft: "21px" }}
                  type="text"
                  placeholder="Company Name"
                  onChange={(e) => {
                    filterContacts(e, "company");
                  }}
                />
                <Input
                  style={{ marginLeft: "5px" }}
                  type="text"
                  placeholder="Job Name"
                  onChange={(e) => {
                    filterContacts(e, "job");
                  }}
                />
                <Input
                  style={{ marginLeft: "5px" }}
                  type="text"
                  placeholder="Job Number"
                  onChange={(e) => {
                    filterContacts(e, "jobNumber");
                  }}
                />
                <Input
                  style={{ marginLeft: "5px" }}
                  type="text"
                  placeholder="City"
                  onChange={(e) => {
                    filterContacts(e, "city");
                  }}
                />
              </div>

              {/* TABLE */}
              <DataTable
                key={1}
                columns={clientColumns}
                data={processClientList(clientList)}
                pagination
                paginationComponentOptions={{
                  selectAllRowsItem: true,
                  selectAllRowsItemText: "All",
                }}
              />
            </div>
          </div>
        </div>
      </div>

      {/* ACTIVITY MODAL */}
      <div id="activityPopUp" className="modal fade" data-bs-backdrop="static">
        <div className="modal-dialog" style={{ maxWidth: `75vw` }}>
          <div className="modal-content">
            {/* HEADER */}
            <div
              className="modal-header text-light"
              style={{
                backgroundColor: "#de2c23",
              }}
            >
              <b style={{ fontSize: "22px" }}>Create Activity</b>
              <button
                className="btn btn-close bg-light"
                onClick={() => onClearForm()}
                data-bs-dismiss="modal"
              />
            </div>
            {/* BODY */}
            <div className="modal-body">
              <div className="d-flex">
                {/* JOB DETAIL */}
                <div className="col">
                  <div className="d-flex flex-column p-2 mb-3">
                    {/* CLIENT */}
                    <div className="lead">
                      <span className="fw-bold me-2">Company:</span>
                      <span>{selectedJob?.companyDetail?.companyName}</span>
                    </div>

                    {/* JOB NAME */}
                    <div className="lead">
                      <span className="fw-bold me-2">Job Name:</span>
                      <span>{selectedJob?.JobDescription}</span>
                    </div>

                    {/* JOB NUMBER */}
                    <div className="lead">
                      <span className="fw-bold me-2">Job Number:</span>
                      <span>{selectedJob?.JobNumber}</span>
                    </div>

                    {/* ADDRESS */}
                    <div className="lead">
                      <span className="fw-bold me-2">Address:</span>
                      <span>{selectedJob?.Address?.Address1}</span>
                    </div>
                  </div>
                  <Map
                    center={{
                      lat: selectedJob?.Address?.Latitude,
                      lng: selectedJob?.Address?.Longitude,
                    }}
                    x
                    zoom={6}
                    change={{
                      lat: selectedJob?.Address?.Latitude,
                      lng: selectedJob?.Address?.Longitude,
                    }}
                    selectData={selectedJob}
                    city={selectedJob?.Address?.City}
                  />
                </div>

                {/* COL SPACER */}
                <div className="col-1" />

                {/* ACTIVITY FORM */}
                <div className="col">
                  {/* CALLER NAME */}
                  <div className="mb-3">
                    <label>Caller Name</label>
                    <input
                      className="form-control"
                      value={callerName}
                      onChange={(x) => setCallerName(x.target.value)}
                    />
                  </div>

                  {/* CALLER PHONE */}
                  <div className="mb-3">
                    <label>Caller Phone Number</label>
                    <input
                      className="form-control"
                      value={callerPhone}
                      onChange={(x) => setCallerPhone(x.target.value)}
                    />
                  </div>

                  {/* ACTIVITY TYPE */}
                  <div className="mb-3">
                    <label>Activity Type *</label>
                    <select
                      className="form-select"
                      value={activityType}
                      onChange={(x) => setActivityType(x.target.value)}
                    >
                      <option value={""}>Select One</option>
                      {activityTypes?.map((item, key) => (
                        <option key={key} value={item?.name}>
                          {item?.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* DISPATCH NOTES */}
                  <div className="mb-3">
                    <label>Dispatcher Notes * </label>
                    <textarea
                      className="form-control"
                      value={notes}
                      onChange={(x) => setNotes(x.target.value)}
                    />
                  </div>

                  {/* STATUS */}
                  <div className="mb-3">
                    <label>Status</label>
                    <select
                      className="form-select"
                      value={status}
                      onChange={(x) => setStatus(x.target.value)}
                    >
                      <option>Closed</option>
                      <option>Open</option>
                    </select>
                  </div>

                  {/* CREATE INCIDENT ? */}
                  <div className="mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={initReport}
                        onChange={(x) => setInitReport(x.target.checked)}
                      />
                      <span className="form-check-span">
                        Launch an Incident Report
                      </span>
                    </div>
                  </div>

                  {/* INCIDENT TYPE */}
                  {/* {initReport && (
                    <div className="mb-3">
                      <label>Incident Type</label>
                      <select
                        className="form-select"
                        value={incidentType}
                        onChange={(x) => setIncidentType(x.target.value)}
                      >
                        {forms.map((item, key) => (
                          <option key={key} value={item?.id}>
                            {item?.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  )} */}

                  {/* EMAIL SITE ? */}
                  {/* <div className="mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        checked={emailSite}
                        onChange={(x) => setEmailSite(x.target.checked)}
                      />
                      <span className="form-check-span">Email Site</span>
                    </div>
                  </div> */}
                </div>
              </div>

              {/* FOOTER */}
              <div className="modal-footer">
                <div className="d-grid">
                  <button
                    className="btn btn-success"
                    onClick={() => onAddNewLog()}
                    data-bs-dismiss="modal"
                    disabled={!notes && !activityType}
                  >
                    Save Activity
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CONFIRM UPDATE MODAL */}
      <div
        id="confirmUpdatePopUp"
        className="modal fade"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog" style={{ maxWidth: `75vw` }}>
          <div className="modal-content">
            {/* HEADER */}
            <div
              className="modal-header text-light"
              style={{
                backgroundColor: "#de2c23",
              }}
            >
              <b style={{ fontSize: "22px" }}>Confirm Activity Update</b>
              <button
                className="btn btn-close bg-light"
                data-bs-dismiss="modal"
              />
            </div>

            {/* BODY */}
            <div className="modal-body">
              {/* PREV NOTES */}
              <div className="mb-3">
                <label>PREVIOUS Dispatcher Notes</label>
                <textarea
                  className="form-control"
                  value={selectedLog?.notes}
                  disabled
                />
              </div>

              {/* NEW NOTES */}
              <div className="mb-3">
                <label>NEW Dispatcher Notes</label>
                <textarea className="form-control" value={notes} disabled />
              </div>
            </div>

            {/* FOOTER */}
            <div className="modal-footer">
              <div className="d-flex w-100 justify-content-between">
                {/* CANCEL BTN */}
                <button
                  className="btn btn-sm btn-success"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    onClearForm();
                  }}
                >
                  Confirm
                </button>

                {/* CONFIRM BTN */}
                <button
                  className="btn btn-sm btn-danger"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    onClearForm();
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStatetoProps = (state) => {
  return {
    history: state.User.history,
    logsSlice: state.Logs,
    dispatcher: state.User,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactList: (jobNumber) => dispatch(getContactList(jobNumber)),
    getLogs: (req) => dispatch(getLogs(req)),
    addNewLog: (req) => dispatch(addNewLog(req)),
    initIncidentReport: (req) => dispatch(initIncidentReport(req)),
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(DispatchTab);
