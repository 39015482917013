import React, { Component } from "react";
import Layout from "./components/Layout/";
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
  Redirect,
} from "react-router-dom";
import {
  caspoiAuthentication,
  restoreReducerData,
  getTeamList,
  initAwlClient,
} from "./store/actions";
import { NotificationContainer } from "react-notifications";
import { BASE_NAME } from "./config/config";
import routes from "./routes";
import { connect } from "react-redux";
import "react-notifications/lib/notifications.css";
// import "./App.scss";
import "./custom.css";

function withLayout(WrappedComponent) {
  // ...and returns another component...
  return class extends Component {
    render() {
      return (
        <Layout>
          <WrappedComponent></WrappedComponent>
        </Layout>
      );
    }
  };
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  componentDidMount() {
    let user = localStorage.getItem("User");
    if (
      this.props &&
      this.props.userDetail &&
      this.props.userDetail.userID == null
    ) {
      let userData = JSON.parse(user);
      this.props.restoreReducerData(userData);
    }
    this.props.caspoiAuthentication();
    this.props.getTeamList();

    const script = document.createElement("script");
    // script.src = 'external-js/AWL-min/awl.js'; // prod
    script.src = "../../external-js/AWL-min/awl.js"; // local
    script.async = true;
    script.onload = () => this.scriptLoaded();

    document.body.appendChild(script);
  }

  scriptLoaded() {
    var awlClient = new window.AWL.client();
    this.props.initAwlClient(awlClient);
  }

  render() {
    const { token } = this.props.userDetail;
    let value = localStorage.getItem("User");
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          value ? (
            <Component {...props} />
          ) : token ? (
            <Component {...props} />
          ) : (
            <Redirect to="/login" />
          )
        }
      />
    );

    return (
      <React.Fragment>
        <div
          className="d-flex flex-fill flex-column bg-dark"
          style={{ height: `100vh` }}
        >
          <Router basename={BASE_NAME}>
            <Switch>
              {routes.map((route, idx) =>
                route.ispublic ? (
                  <Route
                    path={route.path}
                    component={route.component}
                    key={idx}
                  />
                ) : (
                  <PrivateRoute
                    path={route.path}
                    component={withLayout(route.component)}
                    key={idx}
                  />
                )
              )}
            </Switch>
          </Router>
        </div>
         <NotificationContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userDetail: state.User,
    history: state.User.history,
    awlClient: state.Awl.awlClient,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    caspoiAuthentication: () => caspoiAuthentication(),
    restoreReducerData: (data) => dispatch(restoreReducerData(data)),
    getTeamList: () => dispatch(getTeamList()),
    initAwlClient: (data) => dispatch(initAwlClient(data)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
