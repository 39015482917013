import React, { Component } from "react";
// import { Link } from 'react-router-dom';
import {
  isLarge,
  activateAuthLayout,
  getBranchList,
  getContactList,
} from "../../store/actions";
import { connect } from "react-redux";
import logo from "../../images/logo-White.png";
import clogo from "../../images/circle-cropped.png";
import DataTable from "react-data-table-component";
import {
  Button,
  Card,
  CardHeader,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  Pagination,
  Table,
} from "reactstrap";
import Loader from "react-loader-spinner";
import _ from "lodash";
import qs from "qs";
import { Link } from "react-router-dom";
// import logoLight from "../../images/logo.png";
// import logoSmall from "../../images/logo.png";
// import logoLight from "../../images/logo-light.png";
// import logoSmall from "../../images/logo-sm.png";

class Topbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isDialPadOpen: false,
      selectData: { phone: "", client: "" },
      queueData: [],
      transferData: [],
      modalStandard: false,
      isTransferOpen: false,
      isIncidentOpen: false,
      isChooseButton: false,
      isDisableChooseButton: true,
      isHistoryOpen: false,
      startDate: null,
      name: null,
      companyName: null,
      filterData: [],
      contactFilterData: [],
      selectIndex: null,
      isLoader: true,
      location: { lat: 59.95, lng: 30.33 },
      callDetail: {},
      newCallDetail: { timer: "" },
      isCallHold: false,
      brachOption: null,
      branchList: [],
      historyList: [],
      companySelected: null,
      companyData: [],
      response: [],
      filterResponse: [],
      limit: 100000000,
      skip: 0,
      hisLimit: 10,
      hisSkip: 0,
      activePage: 1,
      hisActivePage: 1,
      totalItemCount: 0,
      totalHistoryCount: 0,
      branch: "",
      callerId: null,
      callIndex: -1,
      callStatus: {}, // state
      avayaLoginOpen: false,
      avayaIncomingOpen: false,
      loader: false,
      avaya: { extension: null, password: null },
      call1: null,
      avayaIsDisable: true,
      seconds: 0,
      hisRespose: [],
      callReponse: [],
      updateQueue: false,
      selectClass: "",
      newIndex: null,
      callNewData: [],
      create_menu: false,
      toggle: false,
      columns: [
        {
          name: "Company Name",
          selector: (row) => row?.companyName,
          sortable: true,
        },
        {
          name: "Job Name",
          selector: (row) => row?.jobName,
          sortable: true,
        },
        {
          name: "Job Number",
          selector: (row) => row?.jobNumber,
          sortable: true,
        },
        { name: "City", selector: (row) => row?.city, sortable: true },
        { name: "Action", selector: (row) => row?.action },
      ],
    };
    this.toggleCreate = this.toggleCreate.bind(this);
    this.toggleModel = this.toggleModel.bind(this);
    this.getTeamsList = this.getTeamsList.bind(this);
    this.paginationTeamsList = this.paginationTeamsList.bind(this);
    this.getLatLngSelectedEmployee = this.getLatLngSelectedEmployee.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    // this.handleCall = this.handleCall.bind(this);
    // this.updateCallState = this.updateCallState.bind(this);
    this.avayaLoginToggale = this.avayaLoginToggale.bind(this);
    this.paginationHistoryList = this.paginationHistoryList.bind(this);
    // this.chnageCallHoldState = this.chnageCallHoldState.bind(this);
    this.getNewCallDetailList = this.getNewCallDetailList.bind(this);
  }

  toggleCreate() {
    this.setState((prevState) => ({
      create_menu: !prevState.create_menu,
    }));
  }

  sidebarToggle() {
    document.body.classList.toggle("enlarged");
    this.props.isLarge(!this.props.is_large_state);
  }

  async onLogOut() {
    await localStorage.clear();
    window.location.reload();
  }

  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  // COPIED CODE START ------------------

  paginationHistoryList(page) {
    let { hisActivePage, hisLimit, hisRespose } = this.state;
    hisActivePage = page;
    this.setState({ hisActivePage: page });
    let limit = hisLimit;
    let start = (hisActivePage - 1) * hisLimit;
    let resfliter = _.slice(hisRespose, start, start + limit);
    this.setState({ historyList: resfliter });
  }

  componentDidMount() {
    this._isMounted = true;
    this.props.activateAuthLayout();
    let access_token = localStorage.getItem("access_token");
    if (access_token) {
      this.props
        .getBranchList(access_token)
        .then((res) => {
          this.setState({ branchList: res });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    let page = 1;
    this.getTeamsList(page);
    // this.scriptLoaded();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  // scriptLoaded() {
  //   awlClient = this.props.awlClient;
  //   if (!initCallSetup && awlClient) {
  //     initCallSetup = true;
  //     awlClient.enableLogging();
  //     var appInstanceId = awlClient.generateAppInstanceID();
  //     console.log("The ApplicationInstanceID: " + appInstanceId);

  //     // var awlConfig = {
  //     //   serviceType: "phone",
  //     //   enableVideo: false,
  //     //   Gateway: { ip: "63.246.18.231", port: "8443", user: "GuardMetrics", pwd: "Gu@rdm3trics!@" },
  //     //   Stunserver: { ip: "", port: "3478" },
  //     //   Turnserver: { ip: "", port: "3478", user: "", pwd: "" },
  //     //   AppData: { applicationID: "xxxx12345", applicationUA: "sdktestclient-3.0.0", appInstanceID: appInstanceId },
  //     //   disableResiliency: false
  //     // };
  //     // var awlConfig = {
  //     //   serviceType: "phone",
  //     //   enableVideo: false,
  //     //   Gateway: { ip: "63.246.18.231", port: "9443", user: "GuardMetrics", pwd: "Gu@rdm3trics!@", },
  //     //   Stunserver: { ip: "63.246.18.231", port: "3478", user: "GuardMetrics", pwd: "Gu@rdm3trics!@", },
  //     //   Turnserver: { ip: "63.246.18.231", port: "3478", user: "GuardMetrics", pwd: "Gu@rdm3trics!@", },
  //     //   AppData: { applicationID: "xxxx12345", applicationUA: "sdktestclient-3.0.0", appInstanceID: appInstanceId, },
  //     //   disableResiliency: false,
  //     // };

  //     var awlConfig = {
  //       serviceType: "phone",
  //       enableVideo: true,
  //       Gateway: { ip: "stllabsip.stlcomcloud.com", port: "9443" },
  //       Stunserver: { ip: "stun.1.google.com", port: "19302" },
  //       Turnserver: {
  //         ip: "63.246.18.100",
  //         port: "13478",
  //         user: "stlturn",
  //         pwd: "Turn1tup2468*",
  //       },
  //       AppData: {
  //         applicationID: "xxxx12345",
  //         applicationUA: "sdktestclient-3.0.0",
  //         appInstanceID: appInstanceId,
  //       },
  //       disableResiliency: false,
  //     };

  //     var tags = {
  //       localAudio: "lclAudio",
  //       remoteAudio: "rmtAudio",
  //     };
  //     var onCallListener = this.CallListener();
  //     if (
  //       awlClient.setConfiguration(
  //         awlConfig,
  //         this.onConfigChanged,
  //         this.onRegistrationStateChanged,
  //         onCallListener,
  //         this.onAuthTokenRenewed
  //       ) === "AWL_MSG_SETCONFIG_SUCCESS"
  //     ) {
  //       console.log("\nSETCONFIG SUCCESS");
  //     }
  //     if (awlClient.setDomElements(tags) === "AWL_MSG_SETDOM_FAILED") {
  //       console.log("\nSETDOM FAILED");
  //     } else {
  //       console.log("\nSETDOM PASS");
  //     }
  //     this.avayaLoginToggale();
  //     // awlClient.logIn("5013", "3142057100", "true");
  //   }
  // }

  onConfigChanged(resp) {
    console.log("\n onConfigChanged :: RESULT = " + resp.result);
    console.log("\n onConfigChanged :: reason = " + resp.reason);
  }

  onAuthTokenRenewed(resp) {
    if (resp.result === "AWL_MSG_TOKEN_RENEW_SUCCESS") {
      console.log("\n onAuthTokenRenewed:: Token is successfully renewed");
    } else {
      console.log(
        "\n onAuthTokenRenewed:: Token renewal failed. reason: " + resp.reason
      );
    }
  }

  getTeamsList(page) {
    const activePage = page;
    let { limit } = this.state;
    this.setState({ activePage: page });
    let clientList = localStorage.getItem("clientList");
    let teams = JSON.parse(clientList);
    if (teams && teams.length) {
      limit = page === undefined ? limit : limit * page;
      let start = (activePage - 1) * limit;
      let resfliter = _.slice(teams, start, start + limit);
      this.setState({
        companyData: resfliter,
        totalItemCount: teams.length,
        response: teams,
      });
    }
  }

  paginationTeamsList(page) {
    this.setState({ selectempIndex: null });
    let { filterResponse, response, limit } = this.state;
    const activePage = page;
    limit = page === undefined ? limit : limit;
    let start = (activePage - 1) * limit;
    if (filterResponse && filterResponse.length) {
      let resfliter = filterResponse;
      this.setState({ contactFilterData: resfliter, activePage: page });
    } else {
      let resfliter = _.slice(response, start, start + limit);
      this.setState({ companyData: resfliter, activePage: page });
    }
  }

  openCallQueue() {
    let { hisLimit, hisActivePage } = this.state;
    let { extension } = this.state.avaya;
    let payload = {
      filter: {
        where: { externalNumber: extension !== "" ? extension : "1414" },
        order: ["created DESC"],
      },
    };
    payload = qs.stringify(payload, { addQueryPrefix: true });
    this.props
      .getCallQueue(payload)
      .then((res) => {
        if (res && res.length) {
          let start = (hisActivePage - 1) * hisLimit;
          let resfliter = _.slice(res, start, start + hisLimit);
          this.setState({
            historyList: resfliter,
            hisRespose: res,
            totalHistoryCount: res.length,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  HandleOnchangeSelectCall(data, index, callId) {
    let phone = data && data.phone;
    let { extension } = this.state.avaya;
    let externalPhone = extension;
    const page = 1;
    let callStatus = this.state.callStatus;
    _.forEach(_.cloneDeep(callStatus), function (v) {
      if (v && v.state === "select") {
        v.state = "";
      }
    });

    callStatus[callId] = {
      state:
        callStatus && callStatus[callId] && callStatus[callId].state
          ? callStatus[callId].state
          : "select",
      mute:
        callStatus && callStatus[callId] && callStatus[callId].mute
          ? callStatus[callId].mute
          : false,
    };

    this.setState({
      selectempIndex: null,
      filterResponse: [],
      companyName: "",
      callIndex: callId,
      callStatus: callStatus,
      selectIndex: null,
    });
    this.props
      .getCallDetail(phone, externalPhone)
      .then((res) => {
        if (res && res.id) {
          let result = {
            clientName: res.clientName,
            phone: res.phone,
            externalNumber: externalPhone,
            branch: res.branch,
            clientCmpName: res.client,
            clientJobNumber: res.jobNumber,
          };
          this.setState({
            callerName: res.clientName,
            phoneNumber: res.phone,
            callerId: res.id,
            selectData: result,
            selectempIndex: null,
          });
          this.getTeamsList(page);

          let jobNumber = res && res.jobNumber ? res.jobNumber : "";
          this.props
            .getContactList(jobNumber)
            .then((res) => {
              if (res) {
                let teams = this.state.response;
                let filterClient = _.filter(teams, function (val) {
                  return val.JobNumber === jobNumber;
                });
                let merge = _.merge(filterClient, res);
                this.setState({ transferData: merge });
              } else {
                this.setState({ transferData: [] });
              }
            })
            .catch((err) => {
              this.setState({ transferData: [] });
            });
        } else {
          this.setState({ selectData: data, selectempIndex: null });
          this.getTeamsList(page);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  toggleModel() {
    this.setState((prevState) => ({
      modalStandard: !prevState.modalStandard,
      selectempIndex: null,
      filterResponse: [],
      contactFilterData: [],
      companyName: "",
      isChooseButton: false,
    }));

    const page = 1;
    this.getTeamsList(page);
  }

  submitChooseJob(e) {
    e.preventDefault();
    let { selectData, response, companySelected } = this.state;
    console.log("SELECTDATA: ", selectData, companySelected);
    let selectdata = selectData;
    let data = companySelected;
    selectdata.clientJobNumber = data.JobNumber;
    selectdata.clientCmpName =
      data && data.companyDetail && data.companyDetail.companyName
        ? data.companyDetail.companyName
        : "";
    let clientCmpName =
      data && data.companyDetail && data.companyDetail.companyName
        ? data.companyDetail.companyName
        : "";
    selectdata.clientJobName = data.JobDescription;
    selectdata.clientCity =
      data && data.Address && data.Address.City ? data.Address.City : "";
    selectdata.lat =
      data && data.Address && data.Address.Latitude
        ? data.Address.Latitude
        : "";
    selectdata.lng =
      data && data.Address && data.Address.Longitude
        ? data.Address.Longitude
        : "";
    this.setState({
      selectData: selectdata,
      jobNumber: data.JobNumber,
      client: clientCmpName,
    });
    this.getLatLngSelectedEmployee();
    // this.handleAddAndUpdateCallerDetails();
    this.setState({ modalStandard: false });
    let jobNumber = data && data.JobNumber ? data.JobNumber : "";
    // this.props
    //   .getContactList(jobNumber)
    //   .then((res) => {
    //     if (res) {
    //       let teams = response;
    //       let filterClient = _.filter(teams, function (val) {
    //         return val.JobNumber === jobNumber;
    //       });
    //       let merge = _.merge(filterClient, res);
    //       this.setState({ transferData: merge });
    //     } else {
    //       this.setState({ transferData: [] });
    //     }
    //   })
    //   .catch((err) => {
    //     this.setState({ transferData: [] });
    //   });
  }

  async onSelectCompany(data, index) {
    let { selectempIndex } = this.state;
    await localStorage.setItem("@SelectedJob", JSON.stringify(data));
    index = selectempIndex && selectempIndex === index ? null : index;
    this.setState({
      companySelected: data,
      isDisableChooseButton: false,
      selectempIndex: index,
      isChooseButton: true,
    });
  }

  getLatLngSelectedEmployee() {
    const { lat, lng } = this.state.selectData;
    this.setState({ location: { lat, lng } });
  }

  onHandleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    let { transferData } = this.state;
    if (e.target.value) {
      const filtered = _.filter(transferData, function (o) {
        return o.DisplayName.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
      });
      this.setState({ filterData: filtered });
    } else {
      this.setState({ filterData: transferData });
    }
  }

  onSelectEmployee(data, index) {
    let { selectData } = this.state;
    let selectdata = selectData;
    selectdata.lat =
      data && data.Address && data.Address.Latitude
        ? data.Address.Latitude
        : "";
    selectdata.lng =
      data && data.Address && data.Address.Longitude
        ? data.Address.Longitude
        : "";
    selectdata.employeeName = data.DisplayName;
    selectdata.empPhone = data.phone;
    selectdata.clientJobName = data.JobDescription;
    selectdata.designation = data.designation;
    this.setState({
      selectData: selectdata,
      selectIndex: index,
      isTransferOpen: true,
    });
    this.getLatLngSelectedEmployee();
  }

  handleIncident() {
    this.setState({
      isTransferOpen: false,
      isIncidentOpen: true,
      isHistoryOpen: false,
    });
  }

  handleHistory() {
    this.setState({
      isTransferOpen: false,
      isIncidentOpen: false,
      isHistoryOpen: true,
    });
    this.openCallQueue();
  }

  handleTransfer() {
    this.setState({
      isTransferOpen: true,
      isIncidentOpen: false,
      isHistoryOpen: false,
    });
  }

  filterContacts(e, type) {
    this.setState({ companyName: e.target.value, activePage: 1 });
    let { response, activePage, limit } = this.state;
    if (e.target.value !== "") {
      const filtered = _.filter(response, function (o) {
        if (type === "company") {
          return o?.companyDetail?.companyName
            ?.toLowerCase()
            .includes(e.target.value.toLowerCase());
        } else if (type === "job") {
          return o.JobDescription.toLowerCase().includes(
            e.target.value.toLowerCase()
          );
        } else if (type === "city") {
          return (
            o.Address &&
            o.Address.City.toLowerCase().includes(e.target.value.toLowerCase())
          );
        } else if (type === "jobNumber") {
          return o.JobNumber.toLowerCase().includes(
            e.target.value.toLowerCase()
          );
        }
      });

      let resfliter = filtered;
      if (filtered?.length) {
        this.setState({
          contactFilterData: resfliter,
          totalItemCount: filtered.length,
          filterResponse: filtered,
        });
      } else {
        this.setState({
          contactFilterData: resfliter,
          totalItemCount: filtered.length,
          filterResponse: filtered,
          isLoader: false,
        });
      }
    } else {
      const page = 1;
      this.setState({ selectempIndex: null, filterResponse: [] });
      this.getTeamsList(page);
    }
  }

  handleOnChange(brachOption) {
    let { selectData } = this.state;
    selectData.branch = brachOption.label;
    this.setState({ brachOption, branch: brachOption.label, selectData });
  }

  handleOnCallerInformation(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleAddAndUpdateCallerDetails(e) {
    let {
      queueData,
      callIndex,
      avaya,
      selectData,
      phoneNumber,
      callerName,
      branch,
      callerId,
    } = this.state;
    let clientData = _.filter(queueData, function (w) {
      return w.callId === callIndex;
    });
    let callObject = (clientData && clientData.length && clientData[0]) || {};
    const { extension } = avaya;
    let addPayload = { externalNumber: extension };
    if (_.isEmpty(clientData[0]) === false) {
      if (callerName !== undefined) {
        addPayload.clientName = callerName;
        clientData[0].clientName = callerName;
        selectData.clientName = callerName;
      }
      if (
        (selectData && selectData.phone !== "") ||
        phoneNumber !== undefined
      ) {
        addPayload.phone =
          phoneNumber === undefined ? selectData.phone : phoneNumber;
        clientData[0].phone =
          phoneNumber === undefined ? selectData.phone : phoneNumber;
        selectData.phone =
          phoneNumber === undefined ? selectData.phone : phoneNumber;
      }
      if (branch !== "") {
        addPayload.branch = branch;
        clientData[0].branch = branch;
        selectData.branch = branch;
      }
      if (selectData && selectData.clientCmpName !== undefined) {
        addPayload.client = selectData.clientCmpName;
        clientData[0].client = selectData.clientCmpName;
      }
      if (selectData && selectData.clientJobNumber !== undefined) {
        addPayload.jobNumber = selectData.clientJobNumber;
        clientData[0].jobNumber = selectData.clientJobNumber;
      }
      if (callerId === null && _.isEmpty(addPayload) === false) {
        this.props
          .addCallerInformation(addPayload)
          .then((res) => {
            this.setState({ callerId: res.id, queueData: queueData.push(res) });
          })
          .catch((err) => {
            alert(err);
          });
      } else if (callerId !== null && _.isEmpty(addPayload) === false) {
        this.props
          .updateCallerInformation(callerId, addPayload)
          .then((res) => {
            this.setState({ queueData: queueData });
          })
          .catch((err) => {
            alert(err);
          });
      }
    }
  }

  processList = (list) => {
    let resList = [];
    if (list?.length) {
      _.forEach(list, (item, key) => {
        let listObj = {
          companyName: item.companyDetail.companyName,
          jobName: item.JobDescription,
          jobNumber: item.JobNumber,
          city: item.Address.City,
          action: (
            <button
              className="btn btn-sm btn-success"
              data-bs-dismiss="modal"
              onClick={async (e) => {
                await this.onSelectCompany(item, key);
                // this.submitChooseJob(e);
              }}
            >
              Choose Job
            </button>
          ),
        };
        resList.push(listObj);
      });
    }
    return resList;
  };

  isDialPadToggale(e) {
    let { isDialPadOpen } = this.state;
    this.setState({ isDialPadOpen: isDialPadOpen ? false : true });
  }

  avayaLoginToggale(e) {
    let { avayaLoginOpen } = this.state;
    this.setState({ avayaLoginOpen: avayaLoginOpen ? false : true });
  }

  handleAvayaChange(e) {
    let newState = this.state.avaya;
    const avayaIsDisable = false;
    newState[e.target.name] = e.target.value;
    this.setState(newState);
    this.setState({ avayaIsDisable: avayaIsDisable });
  }

  getNewCallDetailList(newCallDetail) {
    // let {newCallDetail} = this.state
    console.log(newCallDetail, "=======222====-78458=-=-=-=-==-");
  }

  //  COPIED CODE END -------------------

  render() {
    const { selectData, columns } = this.state;

    const contactFilterData = this.state.companyName
      ? this.state.contactFilterData
      : this.state.companyData;

    return (
      <React.Fragment>
        <div className="bg-blue py-1" style={{ height: `10vh` }}>
          <nav className="navbar">
            {/* LOGO */}
            <div className="d-flex centered">
              <img
                src={clogo}
                style={{ height: `6vh` }}
                className="mx-2"
                alt="crop"
              />
              <img
                src={logo}
                style={{
                  height: `6vh`,
                }}
                alt="crop1"
              />
            </div>

            {/* SEARCH BAR */}
            {/* <button
              className="btn btn-sm btn-light"
              data-bs-toggle="modal"
              data-bs-target="#incidentSearchPopUp"
            >
              Client Incident Search
            </button> */}

            {/* LOGOUT BTN */}
            <button
              onClick={this.onLogOut}
              className="btn btn-sm btn-dark me-3"
            >
              Logout
            </button>
          </nav>
        </div>

        {/* SEARCH  MODAL */}
        <div
          id="incidentSearchPopUp"
          className="modal fade"
          data-bs-backdrop="static"
        >
          <div className="modal-dialog" style={{ maxWidth: `75vw` }}>
            <div className="modal-content">
              {/* HEADER */}
              <div
                className="modal-header text-light"
                style={{
                  backgroundColor: "#de2c23",
                }}
              >
                <b style={{ fontSize: "22px" }}>Incident Search</b>
                <button
                  className="btn btn-close bg-light"
                  data-bs-dismiss="modal"
                />
              </div>

              {/* BODY */}
              <div className="modal-body">
                {/* FORM INPUT BAR */}
                <div
                  className="d-flex mb-3"
                  style={{ backgroundColor: "#E1E2E0" }}
                >
                  <Input
                    style={{ marginLeft: "21px" }}
                    type="text"
                    placeholder="Company Name"
                    onChange={(e) => {
                      this.filterContacts(e, "company");
                    }}
                  />
                  <Input
                    style={{ marginLeft: "21px" }}
                    type="text"
                    placeholder="Job Number"
                    onChange={(e) => {
                      this.filterContacts(e, "company");
                    }}
                  />
                  <Input
                    style={{ marginLeft: "5px" }}
                    type="date"
                    placeholder="From Date"
                    onChange={(e) => {
                      this.filterContacts(e, "job");
                    }}
                  />
                  <Input
                    style={{ marginLeft: "5px" }}
                    type="date"
                    placeholder="To Date"
                    onChange={(e) => {
                      this.filterContacts(e, "jobNumber");
                    }}
                  />
                </div>

                <div className="d-grid">
                  <button className="btn btn-success">Search</button>
                </div>
                {/* TABLE */}
                {/* <Table>
                  <thead>
                    <tr style={{ backgroundColor: "#747678" }}>
                      <th>Company Name</th>
                      <th>Job Name</th>
                      <th>Job Number</th>
                      <th>City</th>
                    </tr>
                  </thead>
                  <tbody className="overflow-auto" style={{ height: `50vh` }}>
                    {contactFilterData?.length ? (
                      contactFilterData.map((data, index) => (
                        <tr
                          key={index}
                          onClick={() => {
                            this.onSelectCompany(data, index);
                          }}
                          className={
                            this.state.selectempIndex === index
                              ? "selectEmpJob selectCompany"
                              : "selectCompany"
                          }
                        >
                          <td>
                            {" "}
                            {data &&
                            data.companyDetail &&
                            data.companyDetail.companyName
                              ? data.companyDetail.companyName
                              : ""}{" "}
                          </td>
                          <td>{data.JobDescription}</td>
                          <td>{data.JobNumber}</td>
                          <td>
                            {" "}
                            {data && data.Address && data.Address.City
                              ? data.Address.City
                              : ""}{" "}
                          </td>
                        </tr>
                      ))
                    ) : this.state.isLoader ? (
                      <tr
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <td></td>
                        <td style={{ textAlign: "center", fontSize: "16px" }}>
                          <Loader
                            type="ThreeDots"
                            color="#00BFFF"
                            height={50}
                            width={50}
                            class="loader"
                            timeout={190000}
                          />
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        {" "}
                        <td></td>
                        <td
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            color: "red",
                          }}
                        >
                          <Label> Not found! </Label>
                        </td>{" "}
                      </tr>
                    )}
                  </tbody>
                </Table> */}
                {/* {contactFilterData?.length ? (
                  <Pagination
                    activePage={this.state.activePage}
                    itemsCountPerPage={this.state.limit}
                    totalItemsCount={this.state.totalItemCount}
                    pageRangeDisplayed={5}
                    onChange={this.paginationTeamsList}
                  />
                ) : (
                  ""
                )} */}
              </div>

              {/* FOOTER */}
              {/* <div className="modal-footer">
                <div className="d-grid w-100">
                  <Button
                    className="btn btn-secondary"
                    style={{
                      backgroundColor: "#9A9C9D",
                    }}
                    onClick={(e) => {
                      this.submitChooseJob(e);
                    }}
                    disabled={this.state.isDisableChooseButton ? true : false}
                  >
                    Choose Job
                  </Button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  const { is_large_state } = state.Layout;

  return {
    is_large_state,
    history: state.User.history,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: (token) => getBranchList(token),
    isLarge: (item) => isLarge(item),
    activateAuthLayout: (x) => activateAuthLayout(x),
    // getCallDetail: (phone, externalPhone) =>
    //   getCallDetail(phone, externalPhone),
    // getCallQueue: (payload) => getCallQueue(payload),
    getContactList: (jobNumber) => dispatch(getContactList(jobNumber)),
    // addCallerInformation: (addPayload) => addCallerInformation(addPayload),
    // updateCallerInformation: (id, addPayload) =>
    //   updateCallerInformation(id, addPayload),
  };
};

export default connect(mapStatetoProps, mapDispatchToProps)(Topbar);
