import axios from "axios";
import { API_URL } from "../config/config";

/* User Login */
const login = (data) => {
  return axios
    .post(API_URL + "users/login", data)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/* get Branch List */
const getBranchList = (token) => {
  return axios
    .get(API_URL + `branches/getBranchList?token=${token}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/* get Call Queue */
const getCallQueue = (payload) => {
  return axios
    .get(API_URL + `branches${payload}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/* get Team List */
const getTeamList = () => {
  return axios
    .get(API_URL + `branches/getClientList`)
    .then((res) => {
      console.log("TEAM LIST RES: ", res?.data);
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/* get Contact List */
const getContactList = (jobNumber) => {
  return axios
    .get(API_URL + `branches/getContactsList?jobNumber=${jobNumber}`)
    .then((res) => {
      console.log("CONTACT LIST RES: ", res?.data);
      return res;
    })
    .catch((err) => {
      return err;
    });
};

/* get Call Details */
const getCallDetail = (phone, externalPhone) => {
  return axios
    .get(
      API_URL +
        `branches/getCallDetails?phone=${phone}&externalPhone=${externalPhone}`
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/*  Add Caller Information */
const addCallerInformation = (addPayload) => {
  return axios
    .post(API_URL + `branches`, addPayload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/* Update Caller Information */
const updateCallerInformation = (id, addPayload) => {
  return axios
    .patch(API_URL + `branches/${id}`, addPayload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/* Add Incident Form Report Information */
const addIncidentFormformation = (payload) => {
  return axios
    .post(API_URL + `form-submissions`, payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

/* Get Incident Form Report Information */
const getFormData = (payload) => {
  return axios
    .get(API_URL + `forms/details/` + payload)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err.response;
    });
};

export default {
  login,
  getBranchList,
  getTeamList,
  getCallDetail,
  getCallQueue,
  getContactList,
  addCallerInformation,
  updateCallerInformation,
  addIncidentFormformation,
  getFormData,
};
