import React, { Component } from "react";
import "../../css/bootstrap-pagination.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import {
  activateAuthLayout,
  getBranchList,
  getCallQueue,
  getCallDetail,
  getContactList,
  addCallerInformation,
  updateCallerInformation,
} from "../../store/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import _ from "lodash";
import { DashboardInner } from "./components/DashboardInner";

var awlClient,
  callmap = {},
  callObj1 = null,
  callObj2 = null,
  initCallSetup = false,
  callState = null,
  callDatas = [],
  newCallState = null;

var _this;
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      isDialPadOpen: false,
      selectData: { phone: "", client: "" },
      queueData: [],
      transferData: [],
      modalStandard: false,
      isTransferOpen: false,
      isIncidentOpen: false,
      isChooseButton: false,
      isDisableChooseButton: true,
      isHistoryOpen: false,
      startDate: null,
      name: null,
      companyName: null,
      filterData: [],
      contactFilterData: [],
      selectIndex: null,
      isLoader: true,
      location: { lat: 59.95, lng: 30.33 },
      callDetail: {},
      newCallDetail: {},
      brachOption: null,
      branchList: [],
      historyList: [],
      companySelected: null,
      companyData: [],
      response: [],
      filterResponse: [],
      limit: 10,
      skip: 0,
      hisLimit: 10,
      hisSkip: 0,
      activePage: 1,
      hisActivePage: 1,
      totalItemCount: 0,
      totalHistoryCount: 0,
      branch: "",
      callerId: null,
      callIndex: -1,
      callStatus: {}, // state
      avayaLoginOpen: false,
      avayaIncomingOpen: false,
      loader: false,
      avaya: { extension: null, password: null },
      call1: null,
      avayaIsDisable: true,
      seconds: 0,
      hisRespose: [],
      callReponse: [],
      updateQueue: false,
      selectClass: "",
      newIndex: null,
      view: "Dispatch Activity",
      dispatchActivities: [],
      columnDefs: [{ field: "", flex: 1 }],
    };
    _this = this;
    // this.toggleModel = this.toggleModel.bind(this);
    this.getTeamsList = this.getTeamsList.bind(this);
    // this.paginationTeamsList = this.paginationTeamsList.bind(this);
    // this.getLatLngSelectedEmployee = this.getLatLngSelectedEmployee.bind(this);
    // this.handleOnChange = this.handleOnChange.bind(this);
    // this.handleCall = this.handleCall.bind(this);
    // this.updateCallState = this.updateCallState.bind(this);
    // this.avayaLoginToggale = this.avayaLoginToggale.bind(this);
    // this.changeCallState = this.changeCallState.bind(this);
    // this.paginationHistoryList = this.paginationHistoryList.bind(this);
    // this.chnageCallHoldState = this.chnageCallHoldState.bind(this);
  }
  // chnageCallHoldState() {
  //   let { callIndex, callStatus } = this.state;
  //   if (callIndex === 1 && callState === "CALLHELD") {
  //     callStatus[0].state = "hold";
  //   }
  // }
  // paginationHistoryList(page) {
  //   let { hisActivePage, hisLimit, hisRespose } = this.state;
  //   hisActivePage = page;
  //   this.setState({ hisActivePage: page });
  //   let limit = hisLimit;
  //   let start = (hisActivePage - 1) * hisLimit;
  //   let resfliter = _.slice(hisRespose, start, start + limit);
  //   this.setState({ historyList: resfliter });
  // }

  // changeCallState() {
  //   let { callReponse, callIndex, callStatus } = this.state;
  //   if (newCallState === "CALLDISCONNECTED") {
  //     newCallState = null;
  //     if (callReponse && callReponse.length > 1) {
  //       callReponse = callReponse.slice(0, callReponse.length - 1);
  //     } else {
  //       callReponse = callReponse.slice(1);
  //     }

  //     this.setState({ seconds: 1, callReponse: callReponse, selectClass: "" });
  //   } else {
  //     if (callIndex === 1 && callState === "DISCONNECTED") {
  //       callStatus[0].state = "progress";
  //       this.setState({ callStatus: callStatus, selectClass: "-select" });
  //     }
  //     callState = null;
  //     callReponse = callReponse.slice(1);
  //     this.setState({ seconds: 1, callReponse: callReponse });
  //   }
  // }
  componentDidMount() {
    this.props.activateAuthLayout();
    let access_token = localStorage.getItem("access_token");
    if (access_token) {
      this.props
        .getBranchList(access_token)
        .then((res) => {
          this.setState({ branchList: res });
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // this.getTeamsList(1);
    // this.scriptLoaded();
    // this.avayaLoginToggale();
  }

  // scriptLoaded() {
  //   awlClient = this.props.awlClient;
  //   if (!initCallSetup && awlClient) {
  //     initCallSetup = true;
  //     awlClient.enableLogging();
  //     var appInstanceId = awlClient.generateAppInstanceID();
  //     console.log("The ApplicationInstanceID: " + appInstanceId);

  //     // var awlConfig = {
  //     //   serviceType: "phone",
  //     //   enableVideo: false,
  //     //   Gateway: { ip: "gardasip.stlcomcloud.com", port: "8443", user: "GuardMetrics", pwd: "Gu@rdm3trics!@" },
  //     //   Stunserver: { ip: "", port: "3478" },
  //     //   Turnserver: { ip: "", port: "3478", user: "", pwd: "" },
  //     //   AppData: { applicationID: "xxxx12345", applicationUA: "sdktestclient-3.0.0", appInstanceID: appInstanceId },
  //     //   disableResiliency: false
  //     // };
  //     var awlConfig = {
  //       serviceType: "phone",
  //       enableVideo: false,
  //       Gateway: {
  //         ip: "gardasip.stlcomcloud.com",
  //         port: "9443",
  //         user: "GuardMetrics",
  //         pwd: "Gu@rdm3trics!@",
  //       },
  //       Stunserver: {
  //         ip: "gardasip.stlcomcloud.com",
  //         port: "3478",
  //         user: "GuardMetrics",
  //         pwd: "Gu@rdm3trics!@",
  //       },
  //       Turnserver: {
  //         ip: "gardasip.stlcomcloud.com",
  //         port: "3478",
  //         user: "GuardMetrics",
  //         pwd: "Gu@rdm3trics!@",
  //       },
  //       AppData: {
  //         applicationID: "xxxx12345",
  //         applicationUA: "sdktestclient-3.0.0",
  //         appInstanceID: appInstanceId,
  //       },
  //       disableResiliency: false,
  //     };
  //     var tags = {
  //       localAudio: "lclAudio",
  //       remoteAudio: "rmtAudio",
  //     };
  //     var onCallListener = this.CallListener();
  //     if (
  //       awlClient.setConfiguration(
  //         awlConfig,
  //         this.onConfigChanged,
  //         this.onRegistrationStateChanged,
  //         onCallListener,
  //         this.onAuthTokenRenewed
  //       ) === "AWL_MSG_SETCONFIG_SUCCESS"
  //     ) {
  //       console.log("\nSETCONFIG SUCCESS");
  //     }
  //     if (awlClient.setDomElements(tags) === "AWL_MSG_SETDOM_FAILED") {
  //       console.log("\nSETDOM FAILED");
  //     } else {
  //       console.log("\nSETDOM PASS");
  //     }
  //     this.avayaLoginToggale();
  //     // awlClient.logIn("5013", "3142057100", "true");
  //   }
  // }

  // onConfigChanged(resp) {
  //   console.log("\n onConfigChanged :: RESULT = " + resp.result);
  //   console.log("\n onConfigChanged :: reason = " + resp.reason);
  // }

  // onRegistrationStateChanged(resp) {
  //   console.log("\n onRegistrationStateChange :: RESULT = " + resp.result);
  //   if (resp.result === "AWL_MSG_LOGIN_WEBSOCKET_FAILURE") {
  //     this.avayaLoginToggale();
  //   }
  //   console.log("\n onRegistrationStateChange :: reason = " + resp.reason);
  //   if (resp.result === "AWL_MSG_LOGIN_SUCCESS") {
  //     var alternateServer = awlClient.getAlternateServerConfig();
  //     if (alternateServer !== null) {
  //       console.log(
  //         "Alternate Server:: IP : " +
  //           alternateServer.address +
  //           "\tDomain: " +
  //           alternateServer.domain +
  //           "\tPort: " +
  //           alternateServer.port +
  //           "\tServer Type: " +
  //           alternateServer.serverType
  //       );
  //     } else {
  //       console.log(
  //         "Resiliency is either not supported or not enabled at the server"
  //       );
  //     }
  //   } else if (
  //     resp.result === "AWL_MSG_FAILING_OVER" ||
  //     resp.result === "AWL_MSG_FAILING_BACK"
  //   ) {
  //     alert(resp.reason);
  //   } else if (
  //     resp.result === "AWL_MSG_FAIL_OVER_SUCCESS" ||
  //     resp.result === "AWL_MSG_FAIL_BACK_SUCCESS" ||
  //     resp.result === "AWL_MSG_RELOGGED_IN"
  //   ) {
  //   } else if (
  //     resp.result === "AWL_MSG_FAIL_OVER_FAILED" ||
  //     resp.result === "AWL_MSG_FAIL_BACK_FAILED" ||
  //     resp.result === "AWL_MSG_LOGIN_FAILED"
  //   ) {
  //     _this.avayaLoginToggale();
  //     alert(resp.reason);
  //   } else {
  //   }
  // }

  // onAuthTokenRenewed(resp) {
  //   if (resp.result === "AWL_MSG_TOKEN_RENEW_SUCCESS") {
  //     console.log("\n onAuthTokenRenewed:: Token is successfully renewed");
  //   } else {
  //     console.log(
  //       "\n onAuthTokenRenewed:: Token renewal failed. reason: " + resp.reason
  //     );
  //   }
  // }

  // updateCallState(state) {
  //   let {
  //     selectData,
  //     callStatus,
  //     callReponse,
  //     callIndex,
  //     queueData,
  //     phoneNumber,
  //     callerName,
  //   } = this.state;
  //   let { extension } = this.state.avaya;

  //   if (state === "CONNECTED" && callIndex === 0 && callObj1 !== null) {
  //     let newCall = {
  //       clientName: callObj1.getFarEndName(),
  //       phone: callObj1.getFarEndNumber(),
  //       extension: extension,
  //     };
  //     selectData.clientCmpName = callObj1.getFarEndName();
  //     selectData.empPhone = callObj1.getFarEndNumber();
  //     callReponse.push(newCall);
  //     this.setState({
  //       callDetail: newCall,
  //       loader: false,
  //       callReponse: callReponse,
  //     });
  //   } else if (state === "CONNECTED" && callIndex === 1 && callObj2 !== null) {
  //     let newCall = {
  //       clientName: callObj2.getFarEndName(),
  //       phone: callObj2.getFarEndNumber(),
  //       extension: extension,
  //     };
  //     callReponse.push(newCall);
  //     selectData.clientCmpName = callObj2.getFarEndName();
  //     selectData.empPhone = callObj2.getFarEndNumber();
  //     this.setState({
  //       callDetail: newCall,
  //       loader: false,
  //       callReponse: callReponse,
  //     });
  //   } else {
  //     if (state === "DISCONNECTED") {
  //       let selectObject = queueData && queueData.length ? queueData[0] : {};
  //       queueData = _.filter(queueData, function (val) {
  //         return (
  //           String(val.phone) !==
  //           String((selectObject && selectObject.phone) || null)
  //         );
  //       });
  //     } else if (state === "CALLDISCONNECTED") {
  //       let newIndex = queueData && queueData.length > 1 ? callIndex : 0;
  //       let Object = queueData && queueData.length ? queueData[newIndex] : {};
  //       queueData = _.filter(queueData, function (val) {
  //         return String(val.phone) !== String((Object && Object.phone) || null);
  //       });
  //     }
  //     callDatas = queueData;
  //     selectData.clientName = "";
  //     selectData.phone = "";
  //     selectData.branch = "";
  //     selectData.clientCmpName = "";
  //     selectData.empPhone = "";
  //     if (state === "DISCONNECTED" && callObj1 !== null) {
  //       delete callmap[callObj1.getCallId()];
  //       if (callStatus && callStatus[0] && callStatus[0].state) {
  //         callStatus[0].state = "end";
  //       }
  //       callObj1 = null;
  //     } else if (state === "CALLDISCONNECTED" && callObj2 !== null) {
  //       delete callmap[callObj2.getCallId()];
  //       if (callStatus && callStatus[1] && callStatus[1].state) {
  //         callStatus[1].state = "end";
  //       }
  //       callObj2 = null;
  //     }
  //     let index = queueData && queueData.length ? queueData.length : -1;
  //     this.setState({
  //       callStatus: index !== -1 ? callStatus : {},
  //       callIndex: queueData && queueData.length ? queueData.length : -1,
  //       queueData: queueData && queueData.length ? queueData : [],
  //       updateQueue: false,
  //       loader: false,
  //       phoneNumber: queueData && queueData.length ? phoneNumber : "",
  //       callerName: queueData && queueData.length ? callerName : "",
  //     });
  //   }
  // }

  // CallListener = () => {
  //   var _onNewIncomingCall = function (callId, callObj, autoAnswer) {
  //     alert(
  //       "onNewIncomingCall : getFarEndNumber = " + callObj.getFarEndNumber()
  //     );
  //     console.log("onCallListener : onNewIncomingCall");
  //     console.log(
  //       "onNewIncomingCall : getFarEndNumber = " + callObj.getFarEndNumber()
  //     );
  //     console.log(
  //       "onNewIncomingCall : getFarEndName = " + callObj.getFarEndName()
  //     );
  //     console.log("onNewIncomingCall : getSipUri = " + callObj.getSipUri());
  //     console.log("onNewIncomingCall : autoAnswer = " + autoAnswer);
  //     if (typeof callmap[callId] === "undefined") {
  //       console.log("\n onCallStateChanged : New incoming CALL OBJECT ADDED");
  //       if (callObj1 === null) {
  //         console.log("\n onCallStateChanged : CallObj assigned to callObj1");
  //         callObj1 = callObj;
  //         let call1Object = {
  //           clientName: callObj1.getFarEndName(),
  //           phone: callObj1.getFarEndNumber(),
  //           branch: "",
  //         };
  //         callDatas.push(call1Object);
  //         _this.setState({
  //           queueData: callDatas,
  //           phoneNumber: callObj1.getFarEndNumber(),
  //           callerName: callObj1.getFarEndName(),
  //         });
  //         callmap[callObj1.getCallId()] = callObj1;
  //       } else if (callObj2 === null) {
  //         console.log("\n onCallStateChanged : CallObj assigned to callObj2");
  //         callObj2 = callObj;
  //         let call1Object = {
  //           clientName: callObj2.getFarEndName(),
  //           phone: callObj2.getFarEndNumber(),
  //           branch: "",
  //         };
  //         callDatas.push(call1Object);
  //         _this.setState({
  //           queueData: callDatas,
  //           phoneNumber: callObj2.getFarEndNumber(),
  //           callerName: callObj2.getFarEndName(),
  //         });
  //         callmap[callObj2.getCallId()] = callObj;
  //       } else {
  //         console.log("\n onCallStateChanged : ALL LINES BUSY!!");
  //       }
  //     }
  //   };
  //   var _onCallStateChange = function (callId, callObj, event) {
  //     console.log("\nSDK TEST: onCallStateChanged: ");
  //     console.log("\nSDK TEST: call Id " + callObj.getCallId());

  //     for (var key in callmap) {
  //       console.log("callMap[" + key + "]");
  //     }

  //     if (callObj1 != null && callObj.getCallId() === callObj1.getCallId()) {
  //       console.log("\nSDK TEST: callObj1: Call ID Matched");
  //       console.log("\n callObj1: callstate: " + callObj1.getCallState());
  //       switch (callObj1.getCallState()) {
  //         case "AWL_MSG_CALL_IDLE":
  //           break;
  //         case "AWL_MSG_CALL_CONNECTED":
  //           callState = "CONNECTED";
  //           _this.updateCallState(callState);
  //           break;
  //         case "AWL_MSG_CALL_RINGING":
  //           break;
  //         case "AWL_MSG_CALL_DISCONNECTED":
  //           delete callmap[callObj1.getCallId()];
  //           callState = "DISCONNECTED";
  //           _this.updateCallState(callState);
  //           break;
  //         case "AWL_MSG_CALL_FAILED":
  //           if (callmap[callObj1.getCallId()] !== null) {
  //             // delete callmap[callObj1.getCallId()];
  //             // callObj1 = "";
  //           }
  //           callState = "FAILED";
  //           // _this.updateCallState(callState)
  //           break;
  //         case "AWL_MSG_CALL_INCOMING":
  //           break;
  //         case "AWL_MSG_CALL_HELD":
  //           callState = "CALLHELD";
  //           _this.chnageCallHoldState();
  //           break;
  //         case "AWL_MSG_CALL_FAREND_UPDATE":
  //           console.log(
  //             "onCallStateChange  : getFarEndNumber = " +
  //               callObj1.getFarEndNumber()
  //           );
  //           console.log(
  //             "onCallStateChange  : getFarEndName = " + callObj1.getFarEndName()
  //           );
  //           console.log(
  //             "onCallStateChange  : getSipUri = " + callObj1.getSipUri()
  //           );
  //           break;
  //         default:
  //           console.log("\n CallState doesn't match");
  //       }
  //     } else if (
  //       callObj2 != null &&
  //       callObj.getCallId() === callObj2.getCallId()
  //     ) {
  //       console.log("\ncallObj2: Call ID Matched");
  //       console.log("\n callObj2: callstate: " + callObj2.getCallState());

  //       switch (callObj2.getCallState()) {
  //         case "AWL_MSG_CALL_IDLE":
  //           break;
  //         case "AWL_MSG_CALL_RINGING":
  //           break;
  //         case "AWL_MSG_CALL_CONNECTED":
  //           newCallState = "CONNECTED";
  //           _this.updateCallState(newCallState);
  //           break;
  //         case "AWL_MSG_CALL_DISCONNECTED":
  //           delete callmap[callObj2.getCallId()];
  //           newCallState = "CALLDISCONNECTED";
  //           _this.updateCallState(newCallState);
  //           break;
  //         case "AWL_MSG_CALL_FAILED":
  //           break;
  //         case "AWL_MSG_CALL_INCOMING":
  //           break;
  //         case "AWL_MSG_CALL_HELD":
  //           newCallState = "CALLHELDED";
  //           _this.updateCallState(newCallState);
  //           break;
  //         case "AWL_MSG_CALL_FAREND_UPDATE":
  //           console.log(
  //             "onCallStateChange : getFarEndNumber = " +
  //               callObj2.getFarEndNumber()
  //           );
  //           console.log(
  //             "onCallStateChange : getFarEndName = " + callObj2.getFarEndName()
  //           );
  //           console.log(
  //             "onCallStateChange : getSipUri = " + callObj2.getSipUri()
  //           );
  //           break;
  //         default:
  //           console.log("\n CallState doesn't match");
  //       }
  //     }
  //     console.log(
  //       "\nonCallStateChanged: Total Calls = " + Object.keys(callmap).length
  //     );
  //   };

  //   var _onCallTerminate = function (callId, reason) {
  //     if (callObj1 && callObj1 != null && callObj1.getCallId() === callId) {
  //       delete callmap[callObj1.getCallId()];
  //       callObj1 = null;
  //     } else if (callObj2 != null && callObj2.getCallId() === callId) {
  //       delete callmap[callObj2.getCallId()];
  //       callObj2 = null;
  //     } else {
  //       console.log("Call Id doesn't match ");
  //     }
  //     console.log("\n callTerminate Reason: " + reason);
  //   };

  //   var _onVideoStreamsAvailable = function (
  //     callId,
  //     localStream,
  //     remoteStream
  //   ) {
  //     console.log("\n onVideoStreamsAvailable: callId: " + callId);
  //     if (callId === callObj1.getCallId()) {
  //       awlClient.setMediaStream("lclVideo", localStream, callId, "localVideo");
  //       awlClient.setMediaStream(
  //         "rmtVideo",
  //         remoteStream,
  //         callId,
  //         "remoteVideo"
  //       );
  //     }
  //   };

  //   var _onAudioStreamsAvailable = function (
  //     callId,
  //     localStream,
  //     remoteStream
  //   ) {
  //     if (callId === callObj1.getCallId()) {
  //       awlClient.setMediaStream("1clAudio", localStream, callId, "localAudio");
  //       awlClient.setMediaStream(
  //         "rmtAudio",
  //         remoteStream,
  //         callId,
  //         "remoteAudio"
  //       );
  //     }
  //     console.log("\n onAudioStreamsAvailable: callId: " + callId);
  //   };

  //   return {
  //     onNewIncomingCall: _onNewIncomingCall,
  //     onCallStateChange: _onCallStateChange,
  //     onCallTerminate: _onCallTerminate,
  //     //onCallTerminate: _onCallTerminate,
  //     onVideoStreamsAvailable: _onVideoStreamsAvailable,
  //     onAudioStreamsAvailable: _onAudioStreamsAvailable,
  //   };
  // };

  getTeamsList = async (page) => {
    const teams = JSON.parse(await localStorage.getItem("clientList"));
    const activePage = page;
    let { limit } = this.state;
    this.setState({ activePage: page });
    // console.log("TEAMS: ", teams);
    if (teams && teams.length) {
      limit = page === undefined ? limit : limit * page;
      let start = (activePage - 1) * limit;
      let resfliter = _.slice(teams, start, start + limit);
      this.setState({
        companyData: resfliter,
        totalItemCount: teams.length,
        response: teams,
      });
    }
  };

  // paginationTeamsList(page) {
  //   this.setState({ selectempIndex: null });
  //   let { filterResponse, response, limit } = this.state;
  //   const activePage = page;
  //   limit = page === undefined ? limit : limit;
  //   let start = (activePage - 1) * limit;
  //   if (filterResponse && filterResponse.length) {
  //     let resfliter = _.slice(filterResponse, start, start + limit);
  //     this.setState({ contactFilterData: resfliter, activePage: page });
  //   } else {
  //     let resfliter = _.slice(response, start, start + limit);
  //     this.setState({ companyData: resfliter, activePage: page });
  //   }
  // }

  // openCallQueue() {
  //   let { hisLimit, hisActivePage } = this.state;
  //   let { extension } = this.state.avaya;
  //   let payload = {
  //     filter: {
  //       where: { externalNumber: extension !== "" ? extension : "1414" },
  //       order: ["created DESC"],
  //     },
  //   };
  //   payload = qs.stringify(payload, { addQueryPrefix: true });
  //   this.props
  //     .getCallQueue(payload)
  //     .then((res) => {
  //       if (res && res.length) {
  //         let start = (hisActivePage - 1) * hisLimit;
  //         let resfliter = _.slice(res, start, start + hisLimit);
  //         this.setState({
  //           historyList: resfliter,
  //           hisRespose: res,
  //           totalHistoryCount: res.length,
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // HandleOnchangeSelectCall(data, index) {
  //   let phone = data && data.phone;
  //   let { extension } = this.state.avaya;
  //   let externalPhone = extension;
  //   const page = 1;
  //   let callStatus = this.state.callStatus;
  //   _.forEach(_.cloneDeep(callStatus), function (v) {
  //     if (v && v.state === "select") {
  //       v.state = "";
  //     }
  //   });

  //   callStatus[index] = {
  //     state:
  //       callStatus && callStatus[index] && callStatus[index].state
  //         ? callStatus[index].state
  //         : "select",
  //     mute:
  //       callStatus && callStatus[index] && callStatus[index].mute
  //         ? callStatus[index].mute
  //         : false,
  //   };

  //   this.setState({
  //     selectempIndex: null,
  //     filterResponse: [],
  //     companyName: "",
  //     callIndex: index,
  //     callStatus: callStatus,
  //     selectIndex: null,
  //   });
  //   this.props
  //     .getCallDetail(phone, externalPhone)
  //     .then((res) => {
  //       if (res && res.id) {
  //         let result = {
  //           clientName: res.clientName,
  //           phone: res.phone,
  //           externalNumber: externalPhone,
  //           branch: res.branch,
  //           clientCmpName: res.client,
  //           clientJobNumber: res.jobNumber,
  //         };
  //         this.setState({
  //           callerName: res.clientName,
  //           phoneNumber: res.phone,
  //           callerId: res.id,
  //           selectData: result,
  //           selectempIndex: null,
  //         });
  //         this.getTeamsList(page);

  //         let jobNumber = res && res.jobNumber ? res.jobNumber : "";
  //         this.props
  //           .getContactList(jobNumber)
  //           .then((res) => {
  //             if (res) {
  //               let teams = this.state.response;
  //               let filterClient = _.filter(teams, function (val) {
  //                 return val.JobNumber === jobNumber;
  //               });
  //               let merge = _.merge(filterClient, res);
  //               this.setState({ transferData: merge });
  //             } else {
  //               this.setState({ transferData: [] });
  //             }
  //           })
  //           .catch((err) => {
  //             this.setState({ transferData: [] });
  //           });
  //       } else {
  //         this.setState({ selectData: data, selectempIndex: null });
  //         this.getTeamsList(page);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  // toggleModel() {
  //   this.setState((prevState) => ({
  //     modalStandard: !prevState.modalStandard,
  //     selectempIndex: null,
  //     filterResponse: [],
  //     contactFilterData: [],
  //     companyName: "",
  //     isChooseButton: false,
  //   }));

  //   const page = 1;
  //   this.getTeamsList(page);
  // }

  // submitChooseJob(e) {
  //   e.preventDefault();
  //   let { selectData, response, companySelected } = this.state;
  //   let selectdata = selectData;
  //   let data = companySelected;
  //   selectdata.clientJobNumber = data.JobNumber;
  //   selectdata.clientCmpName =
  //     data && data.companyDetail && data.companyDetail.companyName
  //       ? data.companyDetail.companyName
  //       : "";
  //   let clientCmpName =
  //     data && data.companyDetail && data.companyDetail.companyName
  //       ? data.companyDetail.companyName
  //       : "";
  //   selectdata.clientJobName = data.JobDescription;
  //   selectdata.clientCity =
  //     data && data.Address && data.Address.City ? data.Address.City : "";
  //   selectdata.lat =
  //     data && data.Address && data.Address.Latitude
  //       ? data.Address.Latitude
  //       : "";
  //   selectdata.lng =
  //     data && data.Address && data.Address.Longitude
  //       ? data.Address.Longitude
  //       : "";
  //   this.setState({
  //     selectData: selectdata,
  //     jobNumber: data.JobNumber,
  //     client: clientCmpName,
  //   });
  //   this.getLatLngSelectedEmployee();
  //   this.handleAddAndUpdateCallerDetails();
  //   this.setState({ modalStandard: false });
  //   let jobNumber = data && data.JobNumber ? data.JobNumber : "";
  //   this.props
  //     .getContactList(jobNumber)
  //     .then((res) => {
  //       if (res) {
  //         let teams = response;
  //         let filterClient = _.filter(teams, function (val) {
  //           return val.JobNumber === jobNumber;
  //         });
  //         let merge = _.merge(filterClient, res);
  //         this.setState({ transferData: merge });
  //       } else {
  //         this.setState({ transferData: [] });
  //       }
  //     })
  //     .catch((err) => {
  //       this.setState({ transferData: [] });
  //     });
  // }

  onSelectCompany(data, index) {
    this.setState({
      companySelected: data,
      isDisableChooseButton: false,
      selectempIndex: index,
      isChooseButton: true,
    });
  }

  getLatLngSelectedEmployee() {
    const { lat, lng } = this.state.selectData;
    let location = { lat: lat, lng: lng };
    this.setState({ location: location });
  }

  selectPhoneCallIcons(key) {
    let { callStatus, callIndex, queueData } = this.state;
    if (key === 1) {
      if (callStatus[callIndex]) {
        callStatus[callIndex].mute = !callStatus[callIndex].mute;
        if (callStatus[callIndex].mute) {
          if (
            (callObj1 !== null && callState === "CONNECTED") ||
            callState === "CALLHELD"
          ) {
            if (awlClient.doMute(callObj1.getCallId())) {
              console.log("Mute SUCCESS");
            }
          } else if (
            (callObj2 !== null && newCallState === "CONNECTED") ||
            newCallState === "CALLHELDED"
          ) {
            if (awlClient.doMute(callObj2.getCallId())) {
              console.log("Mute SUCCESS");
            }
          }
        } else {
          if (
            (callObj1 !== null && callState === "CONNECTED") ||
            callState === "CALLHELD"
          ) {
            if (awlClient.doUnMute(callObj1.getCallId())) {
              console.log("UNmute SUCCESS");
            }
          } else if (
            (callObj2 !== null && newCallState === "CONNECTED") ||
            newCallState === "CALLHELDED"
          ) {
            if (awlClient.doUnMute(callObj2.getCallId())) {
              console.log("UNmute SUCCESS");
            }
          }
        }
      }

      this.setState({ callStatus: callStatus });
    } else if (key === 2) {
      if (callStatus[callIndex] && callStatus[callIndex].state === "progress") {
        if (callObj1 !== null && callState === "CONNECTED") {
          if (awlClient.doHold(callObj1.getCallId())) {
            console.log("Hold SUCCESS ,'=================-=-=7458");
            callState = "CALLHELD";
          }

          callStatus[callIndex].state = "hold";
        } else if (callObj2 !== null && newCallState === "CONNECTED") {
          if (awlClient.doHold(callObj2.getCallId())) {
            console.log("Hold SUCCESS ,'=================-=-=7458");
            newCallState = "CALLHELDED";
          }
          if (queueData && queueData.length < 2) {
            this.setState({ newIndex: queueData.length - 1 });
            callStatus[0].state = "hold";
            callStatus[1].state = "hold";
          } else {
            callStatus[callIndex].state = "hold";
          }
        }
      } else if (
        callStatus[callIndex] &&
        callStatus[callIndex].state === "hold"
      ) {
        if (callObj1 !== null && callState === "CALLHELD") {
          if (awlClient.doUnHold(callObj1.getCallId())) {
            console.log("UnHold SUCCESS");
            callState = "CONNECTED";
          }
          callStatus[callIndex].state = "progress";
        } else if (callObj2 !== null && newCallState === "CALLHELDED") {
          if (awlClient.doUnHold(callObj2.getCallId())) {
            console.log("UnHold SUCCESS");
            newCallState = "CONNECTED";
          }
          if (queueData && queueData.length < 2) {
            this.setState({ newIndex: null });
            callStatus[0].state = "progress";
            callStatus[1].state = "progress";
          } else {
            callStatus[callIndex].state = "progress";
          }
        }
      }
      this.setState({ callStatus: callStatus });
    }
  }

  handleCall() {
    let { callStatus, callIndex, selectData } = this.state;
    if (_.isEmpty(callStatus) === false) {
      callIndex =
        callIndex === 0 && newCallState === "CONNECTED" ? 1 : callIndex;
      if (
        callStatus[callIndex] &&
        (callStatus[callIndex].state === "progress" ||
          callStatus[callIndex].state === "hold")
      ) {
        if (callObj1 !== null && callIndex === 0) {
          awlClient.dropCall(callObj1.getCallId());
          delete callmap[callObj1.getCallId()];

          selectData.clientName = "";
          selectData.phone = "";
          selectData.branch = "";
          this.setState({
            callerName: "",
            phoneNumber: "",
          });
        } else if (callObj2 !== null && callIndex === 1) {
          awlClient.dropCall(callObj2.getCallId());
          delete callmap[callObj2.getCallId()];

          selectData.clientName = "";
          selectData.phone = "";
          selectData.branch = "";
          this.setState({
            callerName: "",
            phoneNumber: "",
          });
        }
        callStatus[callIndex].state = "end";
      } else {
        if (callObj1 !== null && callIndex === 0) {
          awlClient.answerCall(callObj1.getCallId());
          callStatus[callIndex].state = "progress";
          this.setState({ loader: true });
        } else if (callObj2 !== null && callIndex === 1) {
          awlClient.answerCall(callObj2.getCallId());
          callStatus[callIndex].state = "progress";
          this.setState({ loader: true });
        }
      }
      this.setState({ callStatus: callStatus });
    }
  }

  onHandleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    let { transferData } = this.state;
    if (e.target.value) {
      const filtered = _.filter(transferData, function (o) {
        return o.DisplayName.toLowerCase().includes(
          e.target.value.toLowerCase()
        );
      });
      this.setState({ filterData: filtered });
    } else {
      this.setState({ filterData: transferData });
    }
  }

  onTransferSubmit(e) {
    e.preventDefault();
    if (callObj1 !== null) {
      awlClient.transferCall("5012", callObj1.getCallId(), "unAttended");
    }
    this.setState({
      isTransferOpen: true,
      isIncidentOpen: false,
      isHistoryOpen: false,
    });
  }

  onSelectEmployee(data, index) {
    let { selectData } = this.state;
    let selectdata = selectData;
    selectdata.lat =
      data && data.Address && data.Address.Latitude
        ? data.Address.Latitude
        : "";
    selectdata.lng =
      data && data.Address && data.Address.Longitude
        ? data.Address.Longitude
        : "";
    selectdata.employeeName = data.DisplayName;
    selectdata.empPhone = data.phone;
    selectdata.clientJobName = data.JobDescription;
    selectdata.designation = data.designation;
    this.setState({
      selectData: selectdata,
      selectIndex: index,
      isTransferOpen: true,
    });
    this.getLatLngSelectedEmployee();
  }

  handleIncident() {
    this.setState({
      isTransferOpen: false,
      isIncidentOpen: true,
      isHistoryOpen: false,
      view: "Incident",
    });
  }

  handleHistory() {
    this.setState({
      // isTransferOpen: false,
      // isIncidentOpen: false,
      // isHistoryOpen: true,
      view: "Dispatch Activity",
    });
    // this.openCallQueue();
  }

  handleTransfer() {
    this.setState({
      isTransferOpen: true,
      isIncidentOpen: false,
      isHistoryOpen: false,
    });
  }

  filterContacts(e, type) {
    this.setState({ companyName: e.target.value, activePage: 1 });
    let { response, activePage, limit } = this.state;
    if (e.target.value !== "") {
      const filtered = _.filter(response, function (o) {
        if (type === "company") {
          return (
            o &&
            o.companyDetail &&
            o.companyDetail.companyName &&
            o.companyDetail.companyName
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          );
        } else if (type === "job") {
          return o.JobDescription.toLowerCase().includes(
            e.target.value.toLowerCase()
          );
        } else if (type === "city") {
          return (
            o.Address &&
            o.Address.City.toLowerCase().includes(e.target.value.toLowerCase())
          );
        } else if (type === "jobNumber") {
          return o.JobNumber.toLowerCase().includes(
            e.target.value.toLowerCase()
          );
        }
      });
      let start = (activePage - 1) * limit;

      let resfliter = _.slice(filtered, start, start + limit);
      if (filtered && filtered.length) {
        this.setState({
          contactFilterData: resfliter,
          totalItemCount: filtered.length,
          filterResponse: filtered,
        });
      } else {
        this.setState({
          contactFilterData: resfliter,
          totalItemCount: filtered.length,
          filterResponse: filtered,
          isLoader: false,
        });
      }
    } else {
      const page = 1;
      this.setState({ selectempIndex: null, filterResponse: [] });
      this.getTeamsList(page);
    }
  }

  handleOnChange(brachOption) {
    let { selectData } = this.state;
    selectData.branch = brachOption.label;
    this.setState({ brachOption, branch: brachOption.label, selectData });
  }

  // handleOnCallerInformation(e) {
  //   this.setState({ [e.target.name]: e.target.value });
  // }

  // handleAddAndUpdateCallerDetails(e) {
  //   let { queueData, callIndex, selectData } = this.state;
  //   let addPayload = { externalNumber: "2222222" };
  //   if (_.isEmpty(queueData) === false) {
  //     if (this.state.callerName !== undefined) {
  //       addPayload.clientName = this.state.callerName;
  //       queueData[callIndex].clientName = this.state.callerName;
  //       selectData.clientName = this.state.callerName;
  //     }
  //     if (
  //       (selectData && selectData.phone !== "") ||
  //       this.state.phoneNumber !== undefined
  //     ) {
  //       addPayload.phone =
  //         this.state.phoneNumber === undefined
  //           ? selectData.phone
  //           : this.state.phoneNumber;
  //       queueData[callIndex].phone =
  //         this.state.phoneNumber === undefined
  //           ? selectData.phone
  //           : this.state.phoneNumber;
  //       selectData.phone =
  //         this.state.phoneNumber === undefined
  //           ? selectData.phone
  //           : this.state.phoneNumber;
  //     }
  //     if (this.state.branch !== "") {
  //       addPayload.branch = this.state.branch;
  //       queueData[callIndex].branch = this.state.branch;
  //       selectData.branch = this.state.branch;
  //     }
  //     if (selectData && selectData.clientCmpName !== undefined) {
  //       addPayload.client = selectData.clientCmpName;
  //       queueData[callIndex].client = selectData.clientCmpName;
  //     }
  //     if (selectData && selectData.clientJobNumber !== undefined) {
  //       addPayload.jobNumber = selectData.clientJobNumber;
  //       queueData[callIndex].jobNumber = selectData.clientJobNumber;
  //     }
  //     if (this.state.callerId === null && _.isEmpty(addPayload) === false) {
  //       // this.props.addCallerInformation(addPayload).then((res) => {
  //       //   this.setState({ callerId: res.id, queueData: queueData.push(res) });
  //       // }).catch((err) => {
  //       //   alert(err);
  //       // });
  //     } else if (
  //       this.state.callerId !== null &&
  //       _.isEmpty(addPayload) === false
  //     ) {
  //       // this.props.updateCallerInformation(this.state.callerId, addPayload).then((res) => {
  //       //   this.setState({ queueData: queueData });
  //       // }).catch((err) => {
  //       //   alert(err);
  //       // });
  //     }
  //   }
  // }

  // isDialPadToggale(e) {
  //   let { isDialPadOpen } = this.state;
  //   this.setState({ isDialPadOpen: isDialPadOpen ? false : true });
  // }

  // avayaLoginToggale(e) {
  //   let { avayaLoginOpen } = this.state;
  //   this.setState({ avayaLoginOpen: avayaLoginOpen ? false : true });
  // }

  // handleAvayaChange(e) {
  //   let newState = this.state.avaya;
  //   const avayaIsDisable = false;
  //   newState[e.target.name] = e.target.value;
  //   this.setState(newState);
  //   this.setState({ avayaIsDisable: avayaIsDisable });
  // }

  // submitAvayaLogin(e) {
  //   e.preventDefault();
  //   let { extension, password } = this.state.avaya;
  //   if (extension !== null && password !== null) {
  //     awlClient.logIn(String(extension), String(password), "true");
  //     this.avayaLoginToggale();
  //   }
  // }
  render() {
    let { selectData, view } = this.state;
    return (
      <div className="d-flex flex-fill bg-light">
        <DashboardInner />
        {/* <Modal
          className="modal-sm"
          isOpen={this.state.avayaLoginOpen}
          toggle={this.avayaLoginToggale}
        >
          <CardHeader style={{ backgroundColor: "rgb(213, 43, 30)" }}>
            <b>Avaya Login</b>
          </CardHeader>
          <Form
            onSubmit={(e) => {
              this.submitAvayaLogin(e);
            }}
          >
            <div className="card">
              <div>
                <Input
                  type="text"
                  placeholder="Extension Number"
                  defaultValue={avaya.extension}
                  name="extension"
                  onChange={(e) => {
                    this.handleAvayaChange(e);
                  }}
                  autoComplete="off"
                  required
                />
                <Input
                  type="password"
                  placeholder="Password"
                  defaultValue={avaya.password}
                  name="password"
                  onChange={(e) => {
                    this.handleAvayaChange(e);
                  }}
                  autoComplete="off"
                  required
                />
              </div>
            </div>
            <Button
              className="btn"
              disabled={this.state.avayaIsDisable ? true : false}
              style={{ width: "100%" }}
            >
              Login
            </Button>
          </Form>
        </Modal> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    contacts: state.Dashboard,
    teams: state.Dashboard.clientList,
    userDetail: state.User,
    JobNumberData: state.JobNumberData,
    history: state.User.history,
    awlClient: state.Awl.awlClient,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBranchList: (token) => getBranchList(token),
    getCallDetail: (phone, externalPhone) =>
      getCallDetail(phone, externalPhone),
    getCallQueue: (payload) => getCallQueue(payload),
    getContactList: (jobNumber) => dispatch(getContactList(jobNumber)),
    addCallerInformation: (addPayload) => addCallerInformation(addPayload),
    updateCallerInformation: (id, addPayload) =>
      updateCallerInformation(id, addPayload),
  };
};
const DashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
  null
)(Dashboard);
export default withRouter(
  connect(null, { activateAuthLayout })(DashboardContainer)
);
