const initialState = {
  isLoading: true,
  accessToken: '',
  history: {},
  userID: null,
  username: null,
  firstName: null,
  lastName: null,
  fullName: null,
  name: null,
  active: null,
  custCode: null,
  shiftDate: null,
  shiftID: null,
  roving: null,
  currentClientName: null,
  currentClient: null,
  currentSite: null,
  empNumber: null,
  employeeGlobalID: null,
  fleetMgmt: null,
  scanOption: null,
  celayxIntegration: null,
  isPasswordSet: "",
  token: null
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case 'CHECK_LOGIN': {
      const { history, username } = action.payload
      if (username) {
        return {
          ...state,
          username,
          history
        };
      } else {
        return {
          ...state,
          initialState
        };
      }

    }
    case 'USER_LOGIN': {
      const { data } = action.payload;
      if (data && data.id) {
        return {
          ...state,
          ...data,
        };
      } else {
        return {
          ...state
        };
      }

    }
    case 'CASPIO_AUTHENTICATION': {
      const { data } = action.payload;
      if (data && data.id) {
        return {
          ...state,
          ...data,
        };
      } else {
        return {
          ...state
        };
      }

    }
    case 'RESTORE': {
      const data = action.payload;
      if (data && data.id) {
        return {
          ...state,
          ...data,
        };
      } else {
        return {
          ...state,
          initialState
        };
      }

    }

    case "USER_LOGOUT": {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}

