
export const initAwlClient = (data) => {
  return {
    type: 'INIT_AWL_CLIENT',
    payload: data
  }
}

export function destroyAwlClient() {
  return {
    type: "DESTROY_AWL_CLIENT"
  }
}
