import React, { useState } from "react";
import DispatchTab from "./DispatchTab";
import { IncidentTab } from "./IncidentTab";
import Map from "../map";

export function DashboardInner() {
  const height = `78vh`;

  const [view, setView] = useState("Dispatch Activity");
  const [selectedJob, setSelectedJob] = useState("");

  return (
    <div className="d-flex flex-fill">
      {/* LEFT PANEL */}
      <div className="col">
        <div className="card-header bg-dark d-flex justify-content-around w-100 py-2">
          {/* <button
            className={`btn text-light fw-bold ${
              view === "Dispatch Activity" ? "bg-blue" : ""
            }`}
            onClick={() => setView("Dispatch Activity")}
          >
          </button>      */}
          <h1 className="display-6 fs-3 fw-bold">Dispatch Activity</h1>

          {/* <button
            className={`btn text-light fw-bold ${
              view === "Incident" ? "bg-blue" : ""
            }`}
            onClick={() => setView("Incident")}
          >
            <h1 className="display-6 fs-3 fw-bold">Incident</h1>
          </button> */}
        </div>

        <div className="d-flex flex-column" style={{ height }}>
          {/* INCIDENT DISPLAY */}
          {/* {view === "Incident" && <IncidentTab />} */}

          {/* DISPATCH ACTIVITY DISPLAY */} 
          <DispatchTab
              selectedJob={selectedJob}
              setSelectedJob={setSelectedJob}
            />
          {/* {view === "Dispatch Activity" && (
           
          )} */}
        </div>
      </div>
    </div>
  );
}
