import axios from 'axios'
import { NotificationManager } from 'react-notifications';
import moment from 'moment'
import * as _ from 'lodash'
import api from '../../../api/index'
import { clientId, clientSecret, API_URL, CASPIO_API_URL } from '../../../config/config'

export const checkLogin = (username, password, history) => {
  return {
    type: 'CHECK_LOGIN',
    payload: { username, password, history }
  }
}

export const setUserDetail = (data) => {
  return {
    type: 'USER_LOGIN',
    payload: data
  }
}

export const setRestoreUserDetail = (data) => {
  return {
    type: 'RESTORE',
    payload: data
  }
}

export function userLogin(data) {
  return (dispatch) => {
    api.login(data).then((res) => {
      dispatch(setUserDetail(res))

      let message;
      if (res.status !== 200) {
        switch (res.status) {
          case 404: message = "Sorry! Not Found!"; break;
          case 500: message = "Network Error!"; break;
          case 401: message = "Invalid credentials"; break;
          default: message = "Invalid credentials"; break;
        }
        NotificationManager.error(message);
      }
      else {
        localStorage.setItem("User", JSON.stringify(res && res.data))
        data.history.push('./dashboard')
      }
    });
  }
}

export function logout() {
  return {
    type: "USER_LOGOUT"
  }
}


export const caspoiAuthentication = () => {
  let data = `grant_type=client_credentials&client_id=${clientId}&client_secret=${clientSecret}`;

  let config = {
    method: 'post',
    url: 'https://c0ect240.caspio.com/oauth/token',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    },
    data: data
  };
  axios(config)
    .then(function (response) {
      localStorage.setItem('access_token', (response && response.data && response.data.access_token));
    })
    .catch(function (error) {
      console.log(error);
    });
}

export const restoreReducerData = (data) => {
  return (dispatch) => {
    dispatch(setRestoreUserDetail(data))
  }
}

// check  UserName on aws Db and caspio
export const checkExistUserName = (username) => {
  let access_token = localStorage.getItem('access_token')
  return axios.get(API_URL + `users/checkUserNameForGarda?userName=${username}`).then(res => {
    if (res && res.status === 200) {
      if (res && res.data) {
        let obj = {
          enableUser: true,
          username: username
        }
        return obj
      } else {

        // get caspio user if mongoDb not exist

        let json = "Username%3D'" + username + "'"

        let data = ''
        let config = {
          method: 'get',
          url: CASPIO_API_URL + `tables/Patrol_Field_Users_Auth/records?q.where=${json}&Cust_Code=UAS`,
          headers: {
            'Authorization': `Bearer ${access_token}`
          },
          data: data
        };

        return axios(config)
          .then(function (response) {
            // enablePassword
            let result = (response && response.data && response.data.Result && response.data.Result[0]) ? response.data.Result[0] : {}
            if (result && result.User_ID) {
              let caspio = {
                enablePassword: true,
                enableUser: true,
                caspioData: result
              }
              return caspio
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }).catch(err => {
    console.log(err)
  })
}

export const addUserOnAws = (caspioData, password) => {
  let data = caspioData
  let payload = {
    "custCode": data.Cust_Code,
    "dateAdded": moment(data.Date_Added).toISOString(),
    "addedBy": data.Added_By,
    "clockedIn": data.Clocked_In,
    "modifiedBy": "",
    "roving": false,
    "currentClientName": "",
    "currentClient": data.Current_Client,
    "currentSite": data.Current_Site,
    "division": data.Division,
    "empNumber": String(data.Emp_Number),
    "employeeGlobalID": data.EmployeeGlobalID,
    "username": _.toLower(data.Username),
    "level": data.Level,
    "firstName": (data && data.FirstName) ? data.FirstName : "null",
    "mI": data.MI,
    "lastName": (data && data.LastName) ? data.LastName : "null",
    "fullName": data.FullName,
    "name": data.Name,
    "active": data.Active,
    "badge": String(data.Badge),
    "email": data.Email,
    "regionBranch": data.Region_Branch,
    "allClients": data.All_Clients,
    "regionLevel": data.Region_Level,
    "branchLevel": data.Branch_Level,
    "region": data.Region,
    "regionID": String(data.Region_ID),
    "branch": data.Branch,
    "branchID": String(data.Branch_ID),
    "company": data.Company,
    "contactNumber": data.Company,
    "custName": data.Cust_Name,
    "inspID": String(data.Insp_ID),
    "restricted": data.Restricted,
    "supervisor": data.Supervisor,
    "status": data.Status,
    "reportsOnly": data.Reports_Only,
    "fieldReports": data.Field_Reports,
    "mSCIDOnly": data.MSC_ID_Only,
    "mSCEmpNumber": data.MSC_Emp_Number,
    "mSCRole": data.MSC_Role,
    "mSCEmpPhoto": (data && data.MSC_Emp_Photo) ? data.MSC_Emp_Photo : "",
    "mSCStateLicense1": (data && data.MSC_State_License_1 != null) ? data.MSC_State_License_1 : "",
    "mSCStateLicense2": (data && data.MSC_State_License_2) ? data.MSC_State_License_2 : "",
    "mSCFSD1": (data && data.MSC_FSD_1 != null) ? data.MSC_FSD_1 : "",
    "mSCFSD2": (data && data.MSC_FSD_2 != null) ? data.MSC_FSD_2 : "",
    "mSCSig": data.MSC_Sig,
    "empInfo": data.Emp_Info,
    "valiantIntegration": data.ValiantIntegration,
    "celayxIntegration": data.CelayxIntegration,
    "f9": data.F9,
    "active1": data.Active1,
    "shiftID": String(data.shiftID),
    "loadID": String(data.loadID),
    "fullName1": String(data.FullName_1),
    "lastFirst1": String(data.Last_First_1),
    "expirationEmail": data.Expiration_Email,
    "timezone": Number(data.Timezone),
    "fleetMgmt": data.FleetMgmt,
    "scanOption": data.ScanOption,
    password: password
  }
  if (data && data.MSC_EE_ID_Expire != null) {
    payload.mSCEEIDExpire = moment(data.MSC_EE_ID_Expire).toISOString()
  }
  if (data && data.MSC_State_Expire != null) {
    payload.mSCStateExpire = moment(data.MSC_State_Expire).toISOString()
  }
  return axios.post(API_URL + `users`, payload).then(res => {
    if (res && res.data) {
      let payload = {
        username: _.toLower(data.Username),
        isSucess: true
      }
      return payload
    }
  }).catch(err => {
    let payload = {
      isSucess: false,
      error: err
    }
    return payload
  })
}
