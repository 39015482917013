import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class SideNav extends Component {

  constructor(props) {
    super(props);
    this.state = {
      active: null
    }
  }
  componentDidMount() {

  }
  render() {
    return (
      <React.Fragment>
        <div id="sidebar-menu">
          <ul className="metismenu" id="menu">
            <li>
              <Link to="/dashboard" className="waves-effect">
                <i className="ti-home"></i> <span> Dashboard </span>
              </Link>
            </li>
          </ul>
        </div>

      </React.Fragment>
    );
  }
}


export default SideNav;