import api from "../../api/index";

export function getBranchList(token) {
  return api
    .getBranchList(token)
    .then((res) => {
      let message;
      if (res.status !== 200) {
        switch (res.status) {
          case 404:
            message = "Something went wrong";
            break;
          case 401:
            message = "Something went wrong";
            break;
          default:
            message = "Something went wrong";
            break;
        }
        alert(message);
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      return err.response;
    });
}

export function getTeamList(token) {
  return (dispatch) => {
    const currentStorage = JSON.parse(localStorage.getItem("clientList"));
    if (!currentStorage?.length)
      return api
        .getTeamList(token)
        .then((res) => {
          console.log("TEAM LIST RES: ", res);
          dispatch(setTeamList(res?.data));
          let message;
          if (res.status !== 200) {
            switch (res.status) {
              case 404:
                message = "Something went wrong";
                break;
              case 401:
                message = "Something went wrong";
                break;
              default:
                message = "Something went wrong";
                break;
            }
            alert(message);
          } else {
            localStorage.setItem("clientList", JSON.stringify(res?.data));
            return res.data;
          }
        })
        .catch((err) => {
          return err.response;
        });
  };
}

export function getCallDetail(phone, externalPhone) {
  return api
    .getCallDetail(phone, externalPhone)
    .then((res) => {
      let message;
      if (res.status !== 200) {
        switch (res.status) {
          case 404:
            message = "Something went wrong";
            break;
          case 401:
            message = "Something went wrong";
            break;
          default:
            message = "Something went wrong";
            break;
        }
        alert(message);
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      return err.response;
    });
}

export function getCallQueue(payload) {
  return api
    .getCallQueue(payload)
    .then((res) => {
      let message;
      if (res.status !== 200) {
        switch (res.status) {
          case 404:
            message = "Something went wrong";
            break;
          case 401:
            message = "Something went wrong";
            break;
          default:
            message = "Something went wrong";
            break;
        }
        alert(message);
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      return err.response;
    });
}

export const setContactList = (data) => {
  return {
    type: "CONTACT_LIST",
    payload: data,
  };
};

export const setTeamList = (data) => {
  return {
    type: "CLIENT_LIST",
    payload: data,
  };
};

export function getContactList(jobNumber) {
  return (dispatch) => {
    return api
      .getContactList(jobNumber)
      .then((res) => {
        console.log("CONTACT LIST: ", res);

        dispatch(setContactList(res));
        if (res.status === 200) {
          return res.data;
        } else {
          return res.error;
        }
      })
      .catch((err) => {
        return err.response;
      });
  };
}

export function addCallerInformation(data) {
  return api
    .addCallerInformation(data)
    .then((res) => {
      let message;
      if (res.status !== 200) {
        switch (res.status) {
          case 404:
            message = "Something went wrong";
            break;
          case 401:
            message = "Something went wrong";
            break;
          default:
            message = "Something went wrong";
            break;
        }
        alert(message);
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      return err.response;
    });
}

export function updateCallerInformation(id, data) {
  return api
    .updateCallerInformation(id, data)
    .then((res) => {
      let message;
      if (res.status !== 204) {
        switch (res.status) {
          case 404:
            message = "Something went wrong";
            break;
          case 401:
            message = "Something went wrong";
            break;
          default:
            message = "Something went wrong";
            break;
        }
        alert(message);
      } else {
        return res.data;
      }
    })
    .catch((err) => {
      return err.response;
    });
}
