export default function logsReducer(
  state = {
    lastFetched: "",
    data: [],
    sites: [],
  },
  action
) {
  switch (action.type) {
    case "GET_LOGS":
      const logs = action?.payload || [];

      return {
        ...state,
        lastFetched: new Date().toISOString(),
        data: logs,
      };
    case "ADD_NEW_LOG":
      if (action?.payload) {
        console.log("ADD NEW LOG RES: ", action?.payload);
      }
      return {
        ...state,
      };
    case "UPDATE_LOG":
      if (action?.payload) {
        console.log("UPDATE LOG RES: ", action?.payload);
      }
      return {
        ...state,
      };
    case "SITES_BY_JOB_NUMBER":
      console.log("SITES_BY_JOB_NUMBER: ", action.payload);
      if (action?.payload) {
        return { ...state, sites: action?.payload || [] };
      }
    default: {
      return {
        ...state,
      };
    }
  }
}
