import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication Module
import Account from "./auth/register/reducer";
import User from "./auth/login/reducer";
import Forget from "./auth/forgetpwd/reducer";
import Dashboard from "./dashboard/reducer";
import Awl from "./awl/reducer";
import Logs from "./logs/reducer";

const rootReducer = combineReducers({
  // public
  Layout,

  // Authentication
  Dashboard,
  Logs,
  Account,
  User,
  Forget,
  Awl,
});

export default rootReducer;
