import Axios from "axios";
import { API_URL, OM_API_URL } from "../../config/config";

// GET => LOGS BY JOB NUM, DISPATCHER, AND DATE RANGE
export const apiGetLogs = ({ dispatcher, start, end }) => {
  console.log(
    `${API_URL}logs/getByDispatcher/${dispatcher}/startDate/${start}/endDate/${end}`
  );
  if (dispatcher && start && end)
    return Axios.get(
      `${API_URL}logs/getByDispatcher/${dispatcher}/startDate/${start}/endDate/${end}`
    )
      .then((res) => {
        return res.data.reverse();
      })
      .catch((err) => console.error("ERROR GET LOGS: ", err));
  else return [];
};
export function getLogs(req) {
  return {
    type: "GET_LOGS",
    payload: apiGetLogs(req),
  };
}

// POST => ADD NEW LOG
export const apiAddNewLog = ({ newLog }) => {
  console.log(`${API_URL}logs`, newLog);
  return Axios.post(`${API_URL}logs`, newLog)
    .then((res) => res.data)
    .catch((err) => console.error("ERROR ADD NEW LOG: ", err));
};
export function addNewLog(req) {
  return {
    type: "ADD_NEW_LOG",
    payload: apiAddNewLog(req),
  };
}

// PUT => UPDATE LOG
export const apiUpdateLog = ({ updatedLog }) => {
  console.log("API REQ: ", updatedLog);
  return Axios.put(`${API_URL}logs/${updatedLog?.id}`, updatedLog)
    .then((res) => res.data)
    .catch((err) => console.error("ERROR UPDATE LOG: ", err));
};
export function updateLog(req) {
  return {
    type: "UPDATE_LOG",
    payload: apiUpdateLog(req),
  };
}

// POST => SUBMIT INCIDENT REPORT
export const apiInitIncidentReport = ({ newReport }) => {
  // axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  // console.log("upload activity " + JSON.stringify(data));
  const URL = `form-submissions`;
  console.log("uploadActivity.post => " + JSON.stringify(newReport));
  return Axios.post(OM_API_URL + URL, newReport)
    .then((res) => {
      // console.log("RES: ", res);
      return res;
    })
    .catch((err) => {
      return err;
    });
};
export function initIncidentReport(req) {
  return {
    type: "INIT_INCIDENT_REPORT",
    payload: apiInitIncidentReport(req),
  };
}

// GET => SITE(S) BY JOB NUMBER
export const apiGetSitesByJobNumber = ({ jobNumber }) => {
  console.log(
    "SITE(S) BY JOB NUMBER REQ: ",
    `${OM_API_URL}sites/jobNumber/${jobNumber}`
  );
  return Axios.get(`${OM_API_URL}sites/jobNumber/${jobNumber}`)
    .then((res) => {
      console.log("SITES(S) BY JOB NUMBER RES", res?.data);
      return res?.data;
    })
    .catch((err) => console.error("ERROR GET SITE(S) BY JOB NUMBER: ", err));
};
export function getSitesByJobNumber(req) {
  return {
    type: "SITES_BY_JOB_NUMBER",
    payload: apiGetSitesByJobNumber(req),
  };
}

// GET => FORMS BY JOB NUMBER
// export const apiGetFormsByJobNumber = ({ jobNumber = "" }) => {
//   console.log("FORMS REQ: ", `${OM_API_URL}forms/jobNumber/${jobNumber}`);
//   return Axios.get(`${OM_API_URL}forms/jobNumber/${jobNumber}`)
//     .then((res) => {
//       console.log("FORMS RES", res);
//       return res?.data;
//     })
//     .catch((err) => console.error("ERROR GET FORMS BY JOB: ", err));
// };
// export function getFormsByJobNumber(req) {
//   return {
//     type: "GET_FORMS_BY_JOB_NUMBER",
//     payload: apiGetFormsByJobNumber(req),
//   };
// }
//  GET => ACTIVITY TYPES
// export const apiGetActivityTypes = ({ jobNumber }) => {
//   console.log(
//     "ACTIVITY TYPES REQ: ",
//     `${OM_API_URL}activity-types/jobNumber/${jobNumber}`
//   );
//   return Axios.get(`${OM_API_URL}activity-types/jobNumber/${jobNumber}`)
//     .then((res) => {
//       console.log("ACTIVITY TYPES RES", res);
//       return res?.data;
//     })
//     .catch((err) => console.error("ERROR GET ACTIVITY TYPES: ", err));
// };

// export function getActivityTypes(req) {
//   return {
//     type: "GET_ACTIVITY_TYPES",
//     payload: apiGetActivityTypes(req),
//   };
// }

// GET => FORM SUBMISSIONS
// export const apiGetFormSubmissions = ({
//   customerId = "",
//   clientId = "",
//   siteId = "",
//   startDate = "",
//   endDate = "",
// }) => {
//   console.log("API REQ: ", {
//     customerId,
//     clientId,
//     siteId,
//     startDate,
//     endDate,
//   });
//   return Axios.get(
//     `${API_URL}form-submissions/customer/${customerId}/client/${clientId}/site/${siteId}/startDate/${startDate}/endDate/${endDate}`
//   )
//     .then((res) => res.data)
//     .catch((err) => console.error("ERROR GET FORM SUBMISSIONS: ", err));
// };
// export function getFormSubmissions(req) {
//   return {
//     type: "GET_FORM_SUBMISSIONS",
//     payload: apiGetFormSubmissions(req),
//   };
// }
