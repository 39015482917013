import Dashboard from './pages/Dashboard/dashboard';
import Pageslogin from './pages/Auth/Login';
import ForgetPassword from './pages/Auth/ForgetPassword';
import ResetPassword from './pages/Auth/ResetPassword';

const routes = [
  // public Routes
  { path: '/login', component: Pageslogin, ispublic: true },
  { path: '/reset-password', component: ResetPassword, ispublic: true },
  { path: '/forget-password', component: ForgetPassword, ispublic: true },

  // Dashnoard
  { path: '/dashboard', component: Dashboard },
  { path: '/', component: Pageslogin, ispublic: true },
];

export default routes;