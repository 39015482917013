//export const API_URL = "http://139.162.56.185:3355/"; // stagging
// export const API_URL = "http://127.0.0.1:3354/"; // local
export const API_URL = "https://gardaworldtruthreport.com/api/"; // prod
export const OM_API_URL = "https://test.guardmetrics.net:3354/"
export const CASPIO_SERVER_HOST = "https://c0ect240.caspio.com/rest/v2/tables";
export const BASE_NAME = "/";
export const CASPIO_API_URL = "https://c0ect240.caspio.com/rest/v2/";
export const clientId = "95e7e85b35bb4095213b300433eac5dd130ad40efb70a351c7";
export const clientSecret =
  "b895cf10432743e48a8c696dd5a855e4a223b49eaa775d5b59";
export const mapKey = "AIzaSyDtnx3pwtsdjwMYdA8WKao5On80x946POM";
