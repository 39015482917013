import React, { Component } from 'react';

class Footer extends Component {

  render() {
    return (
      <React.Fragment>
        <footer className="fixed-bottom bg-blue text-light text-center">
          © {new Date().getFullYear()}  GARDAWORLD <span className="d-none d-sm-inline-block"></span>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;

