import React, { Component } from 'react';
import { Card, Button, Col, Row } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import logosm from '../../images/logo-sm.png';
import { NotificationManager } from 'react-notifications';
import { AvForm, AvField } from 'availity-reactstrap-validation';

import axios from 'axios';
import { API_URL } from '../../config/config';


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = { password: '', cPassword: '', token: '' }
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit(event, values) {
    if (values.password !== values.cPassword) {
      NotificationManager.error('Password does not match!');
    } else {
      axios.post(API_URL + 'users/reset-password',
        { password: values.password },
        { headers: { "Authorization": `Bearer ${this.state.token}` } }
      ).then(res => {
        if (res.status === 200 || res.status === 204) {
          NotificationManager.success('Password reset successfully!');

          this.props.history.push('/login');
        }
      }).catch(err => {
        if (err && err.response) {
          if (err.response.data && err.response.data.error && err.response.data.error.message) {
            NotificationManager.error(err.response.data.error.message);
          } else {
            NotificationManager.error('Error in forgot password!');
          }
        }
      });
    }
  }

  componentDidMount() {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let token = params.get('token');

    if (!token) {
      this.props.history.push('/login');
    } else {
      this.setState({
        token: token
      });
    }
  }

  render() {
    return (
      <React.Fragment>

        <div className="wrapper-page">
          <Card className="overflow-hidden account-card mx-3">
            <div className="bg-blue p-4 text-white text-center position-relative">
              <h4 className="font-20 mb-4">Reset password ?</h4>
              <Link to="/" className="logo logo-admin"><img src={logosm} height="60" alt="logo" /></Link>
            </div>
            <div className="account-card-content">
              <AvForm className="form-horizontal m-t-30" onValidSubmit={this.handleSubmit} >
                <AvField name="password" label="Password" value={this.state.password} placeholder="Enter New Password" type="password" required />
                <AvField name="cPassword" label="Confirm Password" value={this.state.cPassword} validate={{ match: { value: 'password' } }} placeholder="Enter Confirm Password" type="password" required />

                <Row className="form-group m-t-20 mb-0">
                  <Col md="12" className="text-right">
                    <Button color="primary" className="w-md waves-effect waves-light" type="submit">Submit</Button>
                  </Col>
                </Row>

              </AvForm>
            </div>
          </Card>

          <div className="m-t-40 text-center">
            <p>Remember It ? <Link to="/login" className="font-500 blue-primary"> Sign In here </Link> </p>
            <p>© {new Date().getFullYear()} GardaCommunicationCenter</p>
          </div>

        </div>
      </React.Fragment>

    );
  }
}

export default withRouter(ResetPassword);

