const initialState = {
  isLoading: true,
  clientData: [],
  clientList: [],
  contactList: [],
};

export default function dashboardReducer(state = initialState, action) {
  switch (action.type) {
    case "GET_LOGIN_USER_JOBNUMBER": {
      const { data } = action.payload;
      if (data && data.length) {
        return {
          ...state,
          ...data,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    case "CONTACT_LIST": {
      const { data } = action.payload;
      console.log("CONTACT LIST: ", data);
      if (data) {
        return {
          contactList: data,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case "CLIENT_LIST": {
      const { data } = action.payload;

      if (data) {
        return {
          clientList: data,
        };
      } else {
        return {
          ...state,
        };
      }
    }
    default:
      return state;
  }
}
